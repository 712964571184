import React from 'react';
import {Metric} from "./Metrics";
import {ConfrimModal, Modal} from "./modal";
import {cloneDeep} from 'lodash';
import { Stage, Layer, Rect, Group, Shape, Label, Text, Tag } from 'react-konva';



//
// All the math used to calculate Sq. Ft. and Linear Ft.
//
//
// 100px in inch
// i = inches
// i/12 * 100 = total pixels
export const PIXELS_PER_INCH = 100
export const convertInchesToPixels = (inches, decimals = 1) => {
  return parseFloat((inches/12)*PIXELS_PER_INCH).toFixed(decimals) * 1
}
export const convertPixelsToInches = (pixels, decimals = 0) => {
  return parseFloat((pixels/PIXELS_PER_INCH)*12).toFixed(decimals) * 1
}
export const convertPixelsToFeet = (pixels, decimals = 0) => {
  return parseFloat(((pixels/PIXELS_PER_INCH)*12)/12).toFixed(decimals) * 1
}
export const convertInchesToFeet = (inches, decimals = 1) => {
  return parseFloat(inches/12).toFixed(decimals) * 1
}
export const convertShapeSidesFromPixelsToInches = (shape) => {
  const sides = cloneDeep(shape.sides)
  for (const [key, value] of Object.entries(sides)) {
    if(value > 0)
      sides[key] = convertPixelsToInches(value)
  }
  return sides
}
export const calculateSqFtForShape = (shape) => {
  switch(shape.sid){
    case "rs":
      return Math.ceil(convertPixelsToFeet(shape.sides.a * shape.sides.b)/PIXELS_PER_INCH)
    case "ls":
      const rect1 = shape.sides.c * shape.sides.d
      const rect2 = (shape.sides.b - shape.sides.d) * shape.sides.a
      return Math.ceil(convertPixelsToFeet(rect1 + rect2)/PIXELS_PER_INCH)
    case "us":
      const rect10 = shape.sides.d * shape.sides.e
      const rect11 = shape.sides.a * shape.sides.b
      const rect12 = (shape.sides.c - (shape.sides.a + shape.sides.e)) * (shape.sides.d - shape.sides.f)
      return Math.ceil(convertPixelsToFeet(rect10 + rect11 + rect12)/PIXELS_PER_INCH)
    default:
      return -1
  }
}
export const calculateShapeMissingSides = (shape) => {
  switch(shape.sid){
    case "rs":
      shape.sides.c = shape.sides.a
      shape.sides.d = shape.sides.b
      break
    case "ls":
      shape.sides.e = shape.sides.c - shape.sides.a
      shape.sides.f = shape.sides.b - shape.sides.d
      break
    case "us":
      shape.sides.g = shape.sides.c - (shape.sides.a + shape.sides.e)
      shape.sides.h = shape.sides.b - (shape.sides.d - shape.sides.f)
      break
  }
  console.log('calculateShapeMissingSides', shape)
  return shape
}
export const calculateSqFtForBacksplashShape = (shape) => {
  var sqft = 0
  for (let [key, side] of Object.entries(shape.walls)) {
    if(side.hasBacksplash && side.isAgainst){
      var sideLength = shape.sides[key]
      // Rectagle
      if(shape.sid === "rs"){
        if(key === "c")
          sideLength = shape.sides.a
        if(key === "d")
          sideLength = shape.sides.b
      }
      // L-Shape
      else if(shape.sid === "ls"){
        if(key === "e")
          sideLength = shape.sides.c - shape.sides.a
        if(key === "f")
          sideLength = shape.sides.b - shape.sides.d
      }
      // U-Shape
      else if(shape.sid === "us"){
        if(key === "g")
          sideLength = shape.sides.c - (shape.sides.e - shape.sides.a)
        if(key === "h")
          sideLength = shape.sides.b - (shape.sides.d - shape.sides.f)
      }
      sqft += Math.ceil((convertInchesToFeet(side.height,2) * convertPixelsToFeet(sideLength,1)))
    }
  }
  return sqft
}
export const calculateLinearFtForFinishedEdge = (shape) => {
  var lft = 0
  for (let [key, edge] of Object.entries(shape.edges)) {
    if(edge.finished){
      var sideLength = shape.sides[key]
      // Rectagle
      if(shape.sid === "rs"){
        if(key === "c")
          sideLength = shape.sides.a
        if(key === "d")
          sideLength = shape.sides.b
      }
      // L-Shape
      else if(shape.sid === "ls"){
        if(key === "e")
          sideLength = shape.sides.c - shape.sides.a
        if(key === "f")
          sideLength = shape.sides.b - shape.sides.d
      }
      // U-Shape
      else if(shape.sid === "us"){
        if(key === "g")
          sideLength = shape.sides.c - (shape.sides.e - shape.sides.a)
        if(key === "h")
          sideLength = shape.sides.b - (shape.sides.d - shape.sides.f)
      }
      //console.log("calculateLinearFtForFinishedEdge", sideLength, convertPixelsToFeet(sideLength), Math.ceil(convertPixelsToFeet(sideLength,1)))
      lft += Math.ceil(convertPixelsToFeet(sideLength,1))
    }
  }
  return lft
}
export const calculateLinearFtForApplianceEdge = (shape) => {
  var lft = 0
  for (let [key, edge] of Object.entries(shape.edges)) {
    if(edge.appliance){
      var sideLength = shape.sides[key]
      // Rectagle
      if(shape.sid === "rs"){
        if(key === "c")
          sideLength = shape.sides.a
        if(key === "d")
          sideLength = shape.sides.b
      }
      // L-Shape
      else if(shape.sid === "ls"){
        if(key === "e")
          sideLength = shape.sides.c - shape.sides.a
        if(key === "f")
          sideLength = shape.sides.b - shape.sides.d
      }
      // U-Shape
      else if(shape.sid === "us"){
        if(key === "g")
          sideLength = shape.sides.c - (shape.sides.e - shape.sides.a)
        if(key === "h")
          sideLength = shape.sides.b - (shape.sides.d - shape.sides.f)
      }
      lft += Math.ceil(convertPixelsToFeet(sideLength,1))
    }
  }
  return lft
}

class Measurements extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      selectedShape: undefined,
      shapes: [],
      isConfrimDeleteOpen:false,
      isModalLastItemDeleteOpen: false,
      isConfrimResetOpen: false
    }
    this.lastShapeId = 0
    this.addShapeClickHandler = this.addShapeClickHandler.bind(this)
    this.tabClickHandler = this.tabClickHandler.bind(this)
    this.onDragEndHandler = this.onDragEndHandler.bind(this)
    this.onLengthChangeHandler = this.onLengthChangeHandler.bind(this)
    this.onShapeChangeHandler = this.onShapeChangeHandler.bind(this)
    this.onMouseDownHandler = this.onMouseDownHandler.bind(this)
    this.canvasEditBarEventHandler = this.canvasEditBarEventHandler.bind(this)
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this)
    this.onDeleteCancel = this.onDeleteCancel.bind(this)
    this.onResetConfirm = this.onResetConfirm.bind(this)
    this.onResetCancel = this.onResetCancel.bind(this)
    this.onLastItemDeleteModal = this.onLastItemDeleteModal.bind(this)
    this.onChangeSideAgainstWallHandler = this.onChangeSideAgainstWallHandler.bind(this)
    this.onShapesChanged = this.onShapesChanged.bind(this)
    this.onChangeSideEdgeHandler = this.onChangeSideEdgeHandler.bind(this)
  }

  /**
   * Updates the selected shapes edge value
   * @param  {string} id    Edge id
   * @param  {any} value  Value of the edge key
   * @param  {string} field Name of key
   * @return {void}
   */
  onChangeSideEdgeHandler(id, value, field){
    const selected = this.state.selectedShape
    selected.edges[id][field] = value
    this.setState({
      selectedShape: selected
    })
    //console.log("onChangeSideEdgeHandler", id, value, field, selected)
  }

  /**
   * Updates the selected shapes side value
   * @param  {string} id    Side id
   * @param  {any} value  Value of the side key
   * @param  {string} field Name of key
   * @return {void}
   */
  onChangeSideAgainstWallHandler(id, value, field){
    //console.log(id, value, field)
    const selected = this.state.selectedShape
    selected.walls[id][field] = value
    this.setState({
      selectedShape: selected
    })
  }

  /**
   * Handles the Editbar (rotate, reset, delete)
   * @param  {string} action action identifier
   * @return {void}
   */
  canvasEditBarEventHandler(action){
    //console.log(action)
    const rotationDegree = 45
    switch(action){
      case "rotateLeft":
        const selectedrl = this.state.selectedShape
        //const currentRotationrl = selectedrl.rotation - rotationDegree <= -360 ? selectedrl.rotation - rotationDegree : selectedrl.rotation - rotationDegree
        const currentRotationrl = selectedrl.rotation - rotationDegree
        selectedrl.rotation = currentRotationrl
        this.setState({
          selectedShape: selectedrl
        })
      break
      case "rotateRight":
        const selected = this.state.selectedShape
        //const currentRotation = selected.rotation + rotationDegree > 360 ? 0 : selected.rotation + rotationDegree
        const currentRotation = selected.rotation + rotationDegree
        selected.rotation = currentRotation
        this.setState({
          selectedShape: selected
        })
      break
      case "reset":
        this.setState({
          isConfrimResetOpen:true
        })
      break
      case "delete":
        if(this.state.shapes.length > 1){
          this.setState({
            isConfrimDeleteOpen:true
          })
        }
        else {
          this.setState({
            isModalLastItemDeleteOpen:true
          })
        }
      break
      default:
    }
  }

  /**
   * Handles the confirm delete action.
   * @return {void}
   */
  onDeleteConfirm(){
    const items = this.state.shapes.slice();
    if(items.length > 0 ){
      const item = items.find(i => i.selected === true);
      if( item ){
        const index = items.indexOf(item);
        // remove from the list
        items.splice(index, 1);

        // Update id for remaining shapes. So it always starts at 1
        items.forEach((s, index) => s.id = index+1)

        this.setState({
          shapes: items,
          isConfrimDeleteOpen:false
        })
        this.setShapeSelected(items[items.length-1].id)
      }
      this.onShapesChanged(items)
    }
  }

  /**
   * Colse the delete confrim cancel action.
   * @return {void}
   */
  onDeleteCancel(){
    this.setState({
      isConfrimDeleteOpen:false
    })
  }

  /**
   * ?? Closes modal that notifies the user that the last item can't be deleted.
   * @return {void}
   */
  onLastItemDeleteModal(){
    this.setState({
      isModalLastItemDeleteOpen:false
    })
  }

  /**
   * Resets the selected shape to the default values for that shape. Rest button handler
   * @return {void}
   */
  onResetConfirm(){
    const shape = this.state.selectedShape.error ? this.state.selectedShape.cache : this.state.selectedShape
    shape.selected = true
    const originalShape = this.props.shapes.find((s) => s.sid === shape.sid)
    //shape.rotation = cloneDeep(originalShape.rotation)
    shape.sides = cloneDeep(originalShape.sides)
    shape.walls = cloneDeep(originalShape.walls)
    shape.edges = cloneDeep(originalShape.edges)
    shape.style = cloneDeep(originalShape.style)
    const shapes = this.state.shapes.map(s => s.id === shape.id ? shape : s)
    //console.log("RESET",shape)
    this.setState({
      isConfrimResetOpen: false,
      shapes: shapes,
      selectedShape: shapes.find(s => s.id === shape.id)
    })
    this.onShapesChanged(shapes)
  }

  /**
   * Handles rest modal cancel action. Closes modal
   * @return {void}
   */
  onResetCancel(){
    this.setState({
      isConfrimResetOpen:false
    })
  }

  /**
   * Sets the selected shape that was clicked on
   * @param  {event} e     dom event
   * @param  {object} shape shape model
   * @return {void}
   */
  onMouseDownHandler(e, shape){
    this.setShapeSelected(shape.id)
  }

  /**
   * Handles the selcted shape changers button events. Takes selected and replaces it's model with the
   * shape being supplied.
   * @param  {object} shape change to shape model
   * @return {void}
   */
  onShapeChangeHandler(shape){
    const selectedShape = cloneDeep(shape)
    selectedShape.id = this.state.selectedShape.id
    selectedShape.position = cloneDeep(this.state.selectedShape.position)
    //selectedShape.rotation = this.state.selectedShape.rotation
    selectedShape.selected = true
    const shapes = this.state.shapes.map(obj => obj.id === selectedShape.id ? selectedShape : obj);
    this.setState({
      shapes:shapes,
      selectedShape: selectedShape
    })
    this.onShapesChanged(shapes)
  }

  /**
   * Updates the shapes side length. Changes the shapes model side length. COnverts the length to pixels
   * then updates the shap array.
   * @param  {string} key   side id
   * @param  {number} value length value
   * @param  {object} shape shape model
   * @return {void}
   */
  onLengthChangeHandler(key, value, shape){
    shape.sides[key] = convertInchesToPixels(value)
    const shapes = this.state.shapes.map(obj => obj.id === shape.id ? shape : obj);
    this.setState({
      shapes:shapes,
      selectedShape: shapes.find(s => s.id === shape.id)
    })
    this.onShapesChanged(shapes)
  }


  /**
   * It sets the SqFt values of each shape. Sends it up the tree.
   * @param  {array} shapes
   * @return {void}
   */
  onShapesChanged(shapes){
    const shapesClone = shapes ? cloneDeep(shapes) : cloneDeep(this.state.shapes)
    // Convert sides to inches
    shapesClone.forEach((shape) => {
      shape.sqft = calculateSqFtForShape(shape)
      calculateShapeMissingSides(shape)
      for (let [key, value] of Object.entries(shape.sides)) {
        shape.sides[key] = (value > 0 ? convertPixelsToInches(value) : 0)
      }
    })
    this.props.onShapesChanged(shapesClone)
  }

  /**
   * Loops through all shapes and calculates the combined sqft of the shapes. Sends it up the tree.
   * @return {void}
   */
  calculateSqFtForShapes(){
    var sqft = 0
    this.state.shapes.forEach((shape) => {
      sqft += calculateSqFtForShape(shape)
    })
    this.props.onSqftCalculated(sqft)
  }

  /**
   * Calculates the backsplash sqft for ecery shape and sends it up the tree.
   * @return {void}
   */
  calculateSqFtForBacksplashShapes(){
    var sqft = 0
    this.state.shapes.forEach((shape) => {
      sqft += calculateSqFtForBacksplashShape(shape)
    })
    //console.log("calculateSqFtForBacksplashShapes", sqft)
    this.props.onBacksplashSqftCalculated(sqft)
  }

  /**
   * [Calculates the linear feet for all shapes edges. Both finished and appliance
   * @return {void}
   */
  calculateLinearFtForEdgeShapes(){
    this.calculateLinearFtForFinishedEdgeShapes()
    this.calculateLinearFtForApplianceEdgeShapes()
  }

  /**
   * Calculates the total linear foot for the finshed edges for all shapes. Sends it up the tree.
   * @return {void}
   */
  calculateLinearFtForFinishedEdgeShapes(){
    var lft = 0
    this.state.shapes.forEach((shape) => {
      lft += calculateLinearFtForFinishedEdge(shape)
    })
    this.props.onEdgeLinearFtCalculated(lft, "lftFinishedEdge")
  }

  /**
   * Calculates the total linear foot for the appliance edges for all shapes. Sends it up the tree.
   * @return {void}
   */
  calculateLinearFtForApplianceEdgeShapes(){
    var lft = 0
    this.state.shapes.forEach((shape) => {
      lft += calculateLinearFtForApplianceEdge(shape)
    })
    this.props.onEdgeLinearFtCalculated(lft, "lftApplianceEdge")
  }

  /**
   * Updates the shapes position in the model after every drag end.
   * @param  {event} e     dom event
   * @param  {object} shape shape model
   * @return {void}
   */
  onDragEndHandler(e, shape){
    const shapes = this.state.shapes
    shapes.map((s, index) => {
      if(s.id === shape.id){
        s.position.x = e.target.attrs.x
        s.position.y = e.target.attrs.y
      }
      return s
    })
    this.setState({
      shapes:shapes
    })
  }

  /**
   * Adds a new default shape to the shapes array.
   * @param {void} e
   */
  addShapeClickHandler(e){
    this.addShape()
  }

  /**
   * Handles the shape tab click event
   * @param  {object} tab tab model
   * @return {void}
   */
  tabClickHandler(tab){
    this.setShapeSelected(tab.props.id)
  }

  /**
   * Sets the selected shape using it's id
   * @param {number} id shape id
   * @return {void}
   */
  setShapeSelected(id){
    var selectedShape = undefined
    this.state.shapes.forEach((shape)=>{
      if( shape.id === id ){
        selectedShape = shape
        shape.selected = true
      }
      else
        shape.selected = false
    })
    this.setState({
      selectedShape: selectedShape
    })
  }

  /**
   * Adds a new shape to the array by cloneing the default shape.
   * @return {void}
   */
  addShape(){
    const key = this.state.shapes.length + 1
    const shapes = this.state.shapes
    const shape = cloneDeep(this.props.shapes[0])
    shape.id = key
    shapes.push(shape)
    this.setState({
      shapes: shapes,
      selectedShape: shape
    })
    this.setShapeSelected(shape.id)
    this.onShapesChanged(shapes)
  }

  /**
   * Adds the first shape
   * @return {void}
   */
  componentDidMount() {
    if(this.state.shapes.length === 0){
      this.addShape()
    }
  }

  /**
   * Recalculates the 4 different area values evertime this componet updates.
   * @param  {object} prevProps
   * @return {void}
   */
  componentDidUpdate(prevProps) {
    this.calculateSqFtForShapes()
    this.calculateSqFtForBacksplashShapes()
    this.calculateLinearFtForEdgeShapes()
  }

  /**
   *
   * @return {void}
   */
  render() {
    return (
      <div className="estimator-section">
        <h2>{ this.props.localization.title }</h2>
        <hr className="section-seperator" />
        { this.props.localization.info ? (<p>{this.props.localization.info}</p>) : ("")}
        <div className="border-bottom-section d-flex align-items-center shape-tabs">
          <div className="d-flex align-self-stretch">{
            this.state.shapes.map((shape, index)=>{
              return (<ShapeTab id={shape.id} key={index} selected={shape.id === this.state.selectedShape.id} onClick={this.tabClickHandler}/>)
            })
          }</div>
          <button className="btn btn-lg border rounded-0 border-bottom-0 add text-center" onClick={(e) => this.addShapeClickHandler(e)} disabled={this.state.shapes.find(s => s.error === true)}><i className="fas fa-plus"></i><div className="smaller">Add Shape</div></button>
        </div>
        <div className="d-flex" >
          <div className="shape-sides flex-shrink-1 border-bottom border-left border-right">
            <div className="p-3" style={{height:800,overflow:"auto"}}>
              <div className="d-flex mb-3 flex-wrap">
              {
                this.props.shapes.map((shape, index) => {
                  return (<ShapeChanger shape={shape} selected={this.state.selectedShape?.selected && this.state.selectedShape?.sid === shape.sid} onClick={this.onShapeChangeHandler} key={index} />)
                })
              }
              </div>
              <ShapeController shape={this.state.selectedShape} onLengthChange={this.onLengthChangeHandler} onChangeSideAgainstWall={this.onChangeSideAgainstWallHandler} shapeConflictChecker={this.shapeConflictChecker} onChangeSideEdge={this.onChangeSideEdgeHandler} localization={this.props.localization.shape} />
            </div>
          </div>
          <div className="flex-grow-1 overflow-hidden border-bottom border-right position-relative">
            <div className="position-absolute bg-white py-2 px-3" style={{zIndex:10}}>
              <ShapeCanvasEditBar eventHandler={this.canvasEditBarEventHandler} localization={this.props.localization.shape.buttons}  />
            </div>
            <ReactKonva ref={node => {this.stage = node;}} shapes={this.state.shapes} onDragEnd={this.onDragEndHandler} onMouseDown={this.onMouseDownHandler} onImgDataUrlChanged={this.props.onImgDataUrlChanged}/>
          </div>
        </div>
        <ConfrimModal show={this.state.isConfrimDeleteOpen} onCancel={this.onDeleteCancel} onConfirm={this.onDeleteConfirm} cancel={"No"} confirm={"Delete"} title={"Delete Shape " + this.state.selectedShape?.id}>
          Delete Shape {this.state.selectedShape?.id} from your Measurements?
        </ConfrimModal>
        <Modal show={this.state.isModalLastItemDeleteOpen} onClose={this.onLastItemDeleteModal} close={"Ok"} title={"Delete Shape"}>
          The last shape can not be deleted.
        </Modal>
        <ConfrimModal show={this.state.isConfrimResetOpen} onCancel={this.onResetCancel} onConfirm={this.onResetConfirm} cancel={"No"} confirm={"Reset"} title={"Reset Shape " + this.state.selectedShape?.id}>
          Are you sure you want to reset Shape {this.state.selectedShape?.id}?
        </ConfrimModal>
      </div>
    )
  }
}

/**
 * The Tab UI component for each shape.
 * @extends React
 */
class ShapeTab extends React.Component {
  render(){
    const classes = "shape-tab px-3 border rounded-0 border-bottom-0 align-self-stretch d-flex align-items-center" + (this.props.selected ? " selected" : "")
    return (
      <div className={classes} onClick={(e) => this.props.onClick(this)}>
        <div>Shape {this.props.id}</div>
      </div>
    )
  }
}

/**
 * The UI component for the shape changer for the selected shape
 * @extends React
 */
class ShapeChanger extends React.Component {
  render() {
    return (
        <button className={this.props.selected ? "shape-btn btn border flex-grow-1 rounded-0 text-center m-1 selected" : "shape-btn btn border flex-grow-1 rounded-0 text-center m-1"} onClick={(e) => this.props.onClick(this.props.shape)}><img src={this.props.shape?.imageSrc} alt={this.props.shape?.name} className="img-fluid"/><span className="d-block smaller mt-2">{this.props.shape?.name}</span></button>
    )
  }
}

/**
 * UI component for shapes sides. Each shape side will have one of these components.
 * @extends React
 */
class ShapeSideController extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      value: this.props.value,
      isAgainst: this.props.wall?.isAgainst,
      hasBacksplash: this.props.wall?.hasBacksplash,
      backsplashHeight: this.props.wall?.height,
      finishedEdge: this.props.edge?.finished,
      applianceEdge: this.props.edge?.appliance
    }
  }

 /**
  * This is were the side length input is handled. Verifies it's
  * a number, formats it and passes it along to the event listener.
  * @param  {string} key    side id
  * @param  {number} number side length
  * @return {void}
  */
  onChangeHandler(key, number){
    if(isNaN(number)) {
      number = number.replace(/\D/g,'')
      if(number.toString().length === 0)
        number = 0
    }
    else if( !number.toString().length > 0) {
      number = 0
    }
    number = number < 0 ? 0 : parseInt(number)
    this.setState({
      value: number
    })
    this.props.onChange(key, number)
  }

  /**
   * Sets the shapes side is against wall value. Resets the other side opition to false.
   * @param  {event} e
   * @param  {bool} checked
   * @return {void}
   */
  onChangeAgainstWall(e, checked){
    this.setState({isAgainst: checked})
    this.props.onChangeSideAgainstWall(this.props.id, checked, 'isAgainst')
    if(!checked){
      this.onChangeHasBacksplash(e, checked)
    }
    else if(checked){
      this.onChangeFinishedEdge(e, !checked)
      this.onChangeApplianceEdge(e, !checked)
    }
  }

  /**
   * Sets the shapes side is has backsplash value. Resets the other side opition to false.
   * @param  {event} e
   * @param  {bool} checked
   * @return {void}
   */
  onChangeHasBacksplash(e, checked){
    this.setState({hasBacksplash: checked})
    this.props.onChangeSideAgainstWall(this.props.id, checked, 'hasBacksplash')
    if(checked){
      this.onChangeAgainstWall(e, checked)
    }
    else if(checked){
      this.onChangeFinishedEdge(e, !checked)
      this.onChangeApplianceEdge(e, !checked)
    }
  }

  /**
   * Sets the shapes side backsplasg height
   * @param  {number} number the height
   * @return {void}
   */
  onChangeBacksplashHeight(number){
    if(isNaN(number)) {
      number = number.replace(/\D/g,'')
      if(number.toString().length === 0)
        number = 0
    }
    else if( !number.toString().length > 0) {
      number = 0
    }
    //number = parseInt(number, "10")
    number = number < 0 ? 0 : parseInt(number)
    this.setState({backsplashHeight: number})
    this.props.onChangeSideAgainstWall(this.props.id, number, 'height')
  }

  /**
   * Sets the shapes side is has a finished side value. Resets the other side opition to false.
   * @param  {event} e       dom event
   * @param  {bool} checked
   * @return {void}
   */
  onChangeFinishedEdge(e, checked){
    this.setState({finishedEdge: checked})
    this.props.onChangeSideEdge(this.props.id, checked, 'finished')
    if(checked){
      this.onChangeApplianceEdge(e, !checked)
      this.onChangeAgainstWall(e, !checked)
    }
  }

  /**
   * Sets the shapes side is has a appliance side value. Resets the other side opition to false.
   * @param  {event} e       dom event
   * @param  {bool} checked
   * @return {void}
   */
  onChangeApplianceEdge(e, checked){
    this.setState({applianceEdge: checked})
    this.props.onChangeSideEdge(this.props.id, checked, 'appliance')
    if(checked){
      this.onChangeFinishedEdge(e, !checked)
      this.onChangeAgainstWall(e, !checked)
    }
  }

  /**
   * Updates side value to the new props
   * @param  {object} prevProps
   * @param  {object} prevState
   * @return {void}
   */
  componentDidUpdate(prevProps, prevState) {
    if(this.props !== prevProps)
      this.setState({
        value: this.props.value,
        isAgainst: this.props.wall?.isAgainst,
        hasBacksplash: this.props.wall?.hasBacksplash,
        backsplashHeight: this.props.wall?.height,
        finishedEdge: this.props.edge?.finished,
        applianceEdge: this.props.edge?.appliance
      })
  }

  /**
   *
   * @return {void}
   */
  render() {
    //console.log(this.props)
    return (
      <div className="mb-0">
      <label className={this.props.error ? "text-danger" : "" }><span className="font-weight-bold">{this.props.id.toUpperCase()}</span> {this.props.localization.length} {this.props.localization.unit} </label>
      <div className="d-flex justify-content-between">
      <div className="col-3 px-0">
      {
        this.props.enabled ? (<input className={this.props.error ? "form-control col-lg-6 border-danger text-danger side-error" : "form-control"} value={this.state.value} onChange={(e) => this.onChangeHandler(this.props.id, e.target.value)} />) : (<div>{this.props.localization.noLength}</div>)
      }
      </div>
      <div className={this.state.isAgainst === undefined ? "d-none" : "pl-3"}>
        <div>
          <input className="fancy-check" type="checkbox" hidden checked={this.state.isAgainst} onChange={(e) => this.onChangeAgainstWall(e, e.target.checked)} id={this.props.id + "1"} />
          <label className="fancy-check-label" htmlFor={this.props.id + "1"}>
            <span className="fancy-label--text">{this.props.localization.wall.side}</span>
            <span className="fancy-checkbox">
                 <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path className="checkmark-path" fill="none" d="M4 3 l5 4 8-8.5"/>
                </svg>
            </span>
          </label>
        </div>
        <div>
          <input className="fancy-check" hidden type="checkbox" checked={this.state.hasBacksplash} onChange={(e) => this.onChangeHasBacksplash(e, e.target.checked)} id={this.props.id + "2"} />
          <label className="fancy-check-label" htmlFor={this.props.id + "2"}>
            <span className="fancy-label--text">{this.props.localization.wall.backsplash}</span>
            <span className="fancy-checkbox">
                 <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path className="checkmark-path" fill="none" d="M4 3 l5 4 8-8.5"/>
                </svg>
            </span>
          </label>
        </div>
        <div>
        {
          this.state.hasBacksplash > 0 ? (<div className="mb-3"><div>Backsplash Height (in.)</div><input className="form-control col-lg-3" value={this.state.backsplashHeight} onChange={(e) => this.onChangeBacksplashHeight(e.target.value)} /></div>) : ("")
        }
        </div>
      <div>
        <div>
          <input className="fancy-check" type="checkbox" hidden checked={this.state.finishedEdge} onChange={(e) => this.onChangeFinishedEdge(e, e.target.checked)} id={this.props.id + "3"} />
          <label className="fancy-check-label" htmlFor={this.props.id + "3"}>
            <span className="fancy-label--text">{this.props.localization.edge?.finished}</span>
            <span className="fancy-checkbox">
                 <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path className="checkmark-path" fill="none" d="M4 3 l5 4 8-8.5"/>
                </svg>
            </span>
          </label>
        </div>
        <div>
          <input className="fancy-check" hidden type="checkbox" checked={this.state.applianceEdge} onChange={(e) => this.onChangeApplianceEdge(e, e.target.checked)} id={this.props.id + "4"} />
          <label className="fancy-check-label" htmlFor={this.props.id + "4"}>
            <span className="fancy-label--text">{this.props.localization.edge?.appliance}</span>
            <span className="fancy-checkbox">
                 <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path className="checkmark-path" fill="none" d="M4 3 l5 4 8-8.5"/>
                </svg>
            </span>
          </label>
        </div>
      </div>
      </div>
      </div>
      </div>
    )
  }
}

/**
 * Contains the shape side controllers
 * @extends React
 */
class ShapeController extends React.Component {
  timeoutId
  constructor(props){
    super(props)
    this.state = {
      sideErrors:[]
    }
    this.onLengthChangeHandler = this.onLengthChangeHandler.bind(this)
  }
  /*

  */
 /**
  * [onLengthChangeHandler description]
  * @param  {string} key   side id
  * @param  {any} value   length value
  * @return {void}
  */
  onLengthChangeHandler(key, value){
    //console.log(value)
    if(this.timeoutId)
      clearTimeout(this.timeoutId)
    this.timeoutId = setTimeout(() =>
    {
      this.validateShapeSideLengths(key, value, cloneDeep(this.props.shape))
      //this.props.onLengthChange(key, value, this.props.shape)
    },
    750);
  }

  /**
   * Checks if there are any conflicts in length values and the provides a UI message.
   * Also sends the error and new length value up the tree so other componets can adjust there avialabilty/functionality accordinlly.
   * Also sets the shape cache so the shape canvas will not update with the errored values. Also removes the shape cache
   * if the error is fixed.
   * @param  {string} key   side id
   * @param  {number} value side length
   * @param  {object} shape shape the side belons too.
   * @return {void}
   */
  validateShapeSideLengths(key, value, shape){
    console.log(shape.sides.a, shape.sides.c , shape.sides.e, shape.sides.c - shape.sides.e)
    const sideErrors = []
    shape.sides[key] = convertInchesToPixels(value)

    //const changedSide = key

    const sides = convertShapeSidesFromPixelsToInches(shape)

    for (const [key, value] of Object.entries(sides)) {
      if(value === 0)
        sideErrors.push({side:key.toUpperCase(), msg:'Length should be greater than 0'})
    }
    // If there is a zero value it will cause many more errors below. So lets
    // take care of the zero value first.
    if(sideErrors.length === 0){
      // L-Shape
      if(shape.sid === "ls"){
        if(sides.a >= sides.c)
          sideErrors.push({side:"A", msg:'Length should be less than than C'})
        if(sides.c <= sides.a)
          sideErrors.push({side:"C", msg:'Length should be greater than A'})
        if(sides.d >= sides.b)
          sideErrors.push({side:"D", msg:'Length should be less than than B'})
        if(sides.b <= sides.d)
          sideErrors.push({side:"B", msg:'Length should be greater than D'})
      }
      // U-Shape
      else if(shape.sid === "us"){
        console.log(convertPixelsToInches(shape.sides.a), convertPixelsToInches(shape.sides.c) , convertPixelsToInches(shape.sides.e), convertPixelsToInches(shape.sides.c) - convertPixelsToInches(shape.sides.e))
        if(sides.f >= sides.d)
          sideErrors.push({side:"F", msg:'Length should be less than than D'})
        if((sides.e + sides.a) >= sides.c)
          sideErrors.push({side:"C", msg:'Length should be greater than A + E'})
        if(sides.e >= sides.c)
          sideErrors.push({side:"E", msg:'Length should be less than than C'})
        if(sides.e >= (sides.c - sides.a))
          sideErrors.push({side:"E", msg:'Length should be less than than C - A'})
        if(sides.a >= sides.c)
          sideErrors.push({side:"A", msg:'Length should be less than than C'})
        if(sides.a >= (sides.c - sides.e))
          sideErrors.push({side:"A", msg:'Length should be less than than C - E'})
        if((sides.d - sides.f) >= sides.b)
          sideErrors.push({side:"B", msg:'Length should be greater than D - F'})
      }
    }

    shape.sideErrors = sideErrors

    shape.error = sideErrors.length > 0
    // only set the cache once after the first error. If the cache is set after a second error it will
    // contain the error values of the first error. So set it once and then un-set it once all errors
    // have been resolved.
    shape.cache = shape.error ? !shape.cache ? this.props.shape : shape.cache : undefined

    this.props.onLengthChange(key, value, shape)
  }

  /**
   * [render description]
   * @return {[type]} [description]
   */
  render(){
    const inputs = []

    if(this.props.shape){
      Object.keys(this.props.shape?.sides).map((key, index) => {
        inputs.push(<ShapeSideController error={this.props.shape.sideErrors?.find(s => s.side.toLowerCase() === key.toLowerCase()) ? true : false} value={convertPixelsToInches(this.props.shape.sides[key])} enabled={this.props.shape.sides[key] >= 0} id={key} key={index} onChange={this.onLengthChangeHandler} onChangeSideAgainstWall={this.props.onChangeSideAgainstWall} onChangeSideEdge={this.props.onChangeSideEdge} wall={this.props.shape.walls[key]} edge={this.props.shape.edges[key]} localization={this.props.localization.side} />)
        return ""
      })
    }

    const errors = []
    if(this.props.shape?.sideErrors)
    this.props.shape.sideErrors.forEach((err, index) => {
      errors.push(<ShapeSideError key={err.side + index} err={err} />)
    })

    return (
      <div style={{maxWidth:425}}>
        <h5 className="mb-0">{this.props.localization.title}</h5>
        <div className="mb-3 small font-italic">{this.props.localization.minSqFt}</div>
        {this.props.localization.info ? (<p>{this.props.localization.info}</p>) : ("")}
        <div className="text-danger" >
        {
          errors
        }
      </div>
        <div>
        {
          inputs
        }
        </div>
      </div>
    )
  }
}

/**
 * UI component for side error
 * @extends React
 */
class ShapeSideError extends React.Component {
  render(){
    return (
      <span className="text-danger"><b>Side {this.props.err.side.toUpperCase()}</b> {this.props.err.msg}. </span>
    )
  }
}

/**
 * Rotate, Reset and Delete triggers for a shape. Actions are handled in the Measurements class
 * @extends React
 */
class ShapeCanvasEditBar extends React.Component {
  render(){
    return (
      <div className="d-flex flex-wrap">
        <button className="btn canvas-edit-btn" onClick={(e) => this.props.eventHandler("rotateLeft")}><i className="fas fa-undo"></i><span className="d-block">{ this.props.localization.rl }</span></button>
        <button className="btn canvas-edit-btn" onClick={(e) => this.props.eventHandler("rotateRight")}><i className="fas fa-redo"></i><span className="d-block">{ this.props.localization.rr }</span></button>
        <button className="btn canvas-edit-btn" onClick={(e) => this.props.eventHandler("reset")}><i className="fas fa-eraser"></i><span className="d-block">{ this.props.localization.reset }</span></button>
        <button className="btn canvas-edit-btn" onClick={(e) => this.props.eventHandler("delete")}><i className="far fa-trash-alt"></i><span className="d-block">{ this.props.localization.delete }</span></button>
      </div>
    )
  }
}

/**
 * The Reacy-Konva canvas component for the shapes UI.
 * @extends React
 */
class ReactKonva extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      stageWidth: 500,
      stageHeight: 500
    }
    this.onDragEndHandler = this.onDragEndHandler.bind(this)
    this.onMouseDownHandler = this.onMouseDownHandler.bind(this)
    this.MAX_SCALE = .75
    this.PADDING = 50;
    this.imgDataUrl = null;
    this.shapes = this.props.shapes;
  }

  /**
   * Pushes the event up the tree to be handled
   * @param  {event} e     dom event
   * @param  {object} shape shape model
   * @return {void}
   */
  onDragEndHandler(e, shape){
    this.props.onDragEnd(e, shape)
  }

  /**
   * Pushes the event up the tree to be handled
   * @param  {event} e     dom event
   * @param  {object} shape shape model
   * @return {void}
   */
  onMouseDownHandler(e, shape){
    this.props.onMouseDown(e, shape)
  }

  /**
   * This simply resizes the canvas to fit it's container.
   * @type {ResizeObserver}
   */
  ro = new ResizeObserver(entries => {
    for (let entry of entries) {
      const cr = entry.contentRect;
      this.setState({
        stageWidth: cr.width,
        stageHeight: cr.height-70
      });
    }
  })

  /**
   * Positions the groups layer in the center of the stage and scales to fit. With some animation.
   * @return {void}
   */
  scaleStageToFitLayer(){
    const positionAndScale = this.getPositionAndScaleForLayerInStage()
    this.stage.to({
      x: positionAndScale.x,
      y: positionAndScale.y,
      scaleX: positionAndScale.scale,
      scaleY: positionAndScale.scale,
      duration: 0.25
    });
  }

  /**
   * Returns the position, to center vertically and horizonally, and scale using the bounding box of the layer groups
   * @return {void}
   */
  getPositionAndScaleForLayerInStage(){

    const positionAndScale = {x:0,y:0,scale:this.MAX_SCALE}

    const box = this.getBoundingBoxForLayerShapes()

    var scale = Math.min(
      (this.stage.width()-this.PADDING) / (box.width),
      (this.stage.height()-this.PADDING) / (box.height)
    );

    scale = scale > this.MAX_SCALE  ? this.MAX_SCALE  : scale

    positionAndScale.scale = scale

    const stageSize = this.stage.size()

    // I'll be honest here. I have no idea why this seems to work. So I'm sure there will other positing issues as we test.
    const centerX = (-box.x * scale) + (stageSize.width - (box.width * scale)) / 2
    const centerY = (-box.y * scale) + (stageSize.height- (box.height * scale)) / 2

    positionAndScale.x = centerX
    positionAndScale.y = centerY

    return positionAndScale
  }

  /**
   * Calculate the bounding of the layer groups.
   * @return {void}
   */
  getBoundingBoxForLayerShapes(){
    const box ={x:0,y:0,width:0,height:0}
    const children = this.layer.getChildren()
    const points = []
    children.forEach((child) => {
      const rect = child.getClientRect({ relativeTo: this.layer })
      points.push({x:rect.x, y:rect.y})
      points.push({x:rect.x + rect.width, y:rect.y + rect.height})
    })

    // Detemine the bounding box
    if(points.length > 0){
      // min y
      const miny = points.reduce((min, p) => p.y < min ? p.y : min, points[0].y)
      // max y
      const maxy = points.reduce((max, p) => p.y > max ? p.y : max, points[0].y)
      // min x
      const minx = points.reduce((min, p) => p.x < min ? p.x : min, points[0].x)
      // max x
      const maxx = points.reduce((max, p) => p.x > max ? p.x : max, points[0].x)

      box.x = minx
      box.y = miny
      box.width = maxx - minx
      box.height = maxy - miny
    }
    return box
  }

  /**
   * It appears this is not beeing used. Will be removed.
   * @param  {number} w width
   * @return {void}
   */
  widthOnChangeHandler(w){
    this.setState({
      width:(w*2.5)
    })
  }

  /**
   * fits the canvas to it container when component is loaded.
   * @return {void}
   */
  componentDidMount() {
    this.ro.observe(this.container.parentNode)
    this.scaleStageToFitLayer()
  }

  /**
   * Removes the ResizeObserver
   * @return {void}
   */
  componentWillUnmount() {
    this.ro.unobserve(this.container.parentNode)
  }

  /**
   * Scales canvas to fit all shapes. Also produces an image of the shape to be used later.
   * @param  {object} prevProps
   * @param  {object} prevState
   * @return {void}
   */
  componentDidUpdate(prevProps, prevState) {
    this.scaleStageToFitLayer() 
    // Delay the image until the animation and positioning settles.
    setTimeout(()=>{
      this.getPositionAndScaleForLayerInStage()
      const imgDataUrl = this.stage.toDataURL()//this.stage.toDataURL({ pixelRatio: 2, width:box.width+box.x, height: box.height+box.y, x: box.x, y: box.y })
      sessionStorage.setItem("shapesImgBase64", imgDataUrl)
      this.stage.toImage({
        callback(img) {
          sessionStorage.setItem("shapesImg", img)
        }
      })
    },1500)
  }

  /**
   *
   * @return {void}
   */
  render() {
    const shapes = []
    this.props.shapes.forEach((shape, index) => {
      // Set the default position of a new shape. defined by postion values being 0
      const box = this.getBoundingBoxForLayerShapes()
      shape.position.x = shape.position.x === 0 ? ((box.width/2)+box.x)+(50*this.props.shapes.length) : shape.position.x
      shape.position.y = shape.position.y === 0 ? ((box.height/2)+box.y)+(50*this.props.shapes.length) : shape.position.y

      if(shape.error){
        shape.cache.style.selectedFill = "#dc3545"
        shape.cache.style.fill = "#dc3545"
      }

      var shapeElem

      if(shape.sid === "rs")
        shapeElem = (<RectShape selected={shape.selected} draggable={shape.selected ? true : true} key={index} data={shape.error ? shape.cache : shape} onDragEnd={this.onDragEndHandler} onMouseDown={this.onMouseDownHandler} />)
      else if(shape.sid === "ls")
        shapeElem = (<LShape draggable={shape.selected ? true : true} key={index} data={shape.error ? shape.cache : shape} onDragEnd={this.onDragEndHandler} onMouseDown={this.onMouseDownHandler} onMouseUp={this.onMouseUpHandler} />)
      else if(shape.sid === "us")
        shapeElem = (<UShape draggable={shape.selected ? true : true} key={index} data={shape.error ? shape.cache : shape} onDragEnd={this.onDragEndHandler} onMouseDown={this.onMouseDownHandler} onMouseUp={this.onMouseUpHandler} />)

      // Make sure a selected shape is at the end of the array
      shape.selected ? shapes.push(shapeElem) : shapes.unshift(shapeElem)

    })

    return (
      <div className="row col" style={{marginTop:70}} ref={node => {this.container = node;}}>
        <Stage width={this.state.stageWidth} height={this.state.stageHeight} ref={node => {this.stage = node;}} scaleX={this.MAX_SCALE} scaleY={this.MAX_SCALE}>
          <Layer ref={node => {this.layer = node;}}>
            { shapes }
          </Layer>
        </Stage>
      </div>
    )
  }
}

/**
 * Konva Rectangle Shape Class
 * @extends React
 */
class RectShape extends React.Component {
  render(){
    const { walls, edges } = this.props.data;
    var side = {a:0,b:0}

    if(this.props.data.sides)
      side = this.props.data.sides

    if(this.shapeRef){
      if(this.props.data.selected){
        this.shapeRef.moveToTop()
      }
      this.shapeRef.to({
        rotation: this.props.data.rotation,
        duration: 0.25
      })
    }

    if(this.shapeLbl){
      this.shapeLbl.to({
        rotation: -this.props.data.rotation,
        duration: 0.25
      })
    }

    return (
      <React.Fragment>
        <Group x={this.props.data.position.x} y={this.props.data.position.y} offsetX={side.b/2} offsetY={side.a/2} id={this.props.data.id} rotation={this.props.data.rotation} draggable={this.props.draggable} onDragEnd={(e) => this.props.onDragEnd(e, this.props.data)} onMouseDown={(e) => this.props.onMouseDown(e, this.props.data)} ref={node => {this.shapeRef = node}}>
          <Rect x={0} y={0} {...{width:side.b, height:side.a}} {...this.props.data.style}   ref={node => {this.shape = node;}} fill={this.props.data.selected ? this.props.data.style.selectedFill : this.props.data.style.fill} />
          <Metric {...{width:side.b}} direction="horizontal" position="top" label="B" rotation={-this.props.data.rotation} wall={walls.b} edge={edges.b}/>
          <Metric {...{width:side.b, height:side.a}} direction="vertical" position="right" label="A" rotation={-this.props.data.rotation} wall={walls.a} edge={edges.a}/>
          <Metric {...{width:side.b, height:side.a}} direction="horizontal" position="bottom" label="D" rotation={-this.props.data.rotation} wall={walls.d} edge={edges.d}/>
          <Metric {...{width:side.b, height:side.a}} direction="vertical" position="left" label="C" rotation={-this.props.data.rotation} wall={walls.c} edge={edges.c}/>
          <Group x={0} y={side.a+25} rotation={this.props.data.rotation} ref={node => {this.shapeLbl = node}}>
            <Label y={10}>
              <Tag fill={this.props.data.selected ? this.props.data.style.lblSelectedFill : this.props.data.style.lblFill}/>
              <Text text={"Shape " + this.props.data.id} padding={10} fontSize={20} fill="#fff" fontStyle="bold"/>
            </Label>
          </Group>
        </Group>
      </React.Fragment>
    )
  }
}

/**
 * Konva L-SHape Class
 * @extends React
 */
class LShape extends React.Component {
  render(){
    const { walls, edges } = this.props.data;
    const verticalMetricPadding = 5
    const horizontalMetricPadding = 15

    var side = {a:90,b:300,c:300,d:90}
    if(this.props.data.sides)
      side = this.props.data.sides

    if(this.shapeRef){
      if(this.props.data.selected){
        this.shapeRef.moveToTop()
      }
      this.shapeRef.to({
        rotation: this.props.data.rotation,
        duration: 0.25
      })
    }

    if(this.shapeLbl){
      this.shapeLbl.to({
        rotation: -this.props.data.rotation,
        duration: 0.25
      })
    }

    var x = 0//(side.b/2)-50
    var y = side.c+25 //side.c > side.a ? side.c+30 : side.a+30

    return (
      <Group {...this.props.data.position} offsetX={side.b/2} offsetY={side.c/2} rotation={this.props.data.rotation} draggable={this.props.draggable} id={this.props.data.id} onDragEnd={(e) => this.props.onDragEnd(e, this.props.data)} onMouseDown={(e) => this.props.onMouseDown(e, this.props.data)} ref={node => {this.shapeRef = node}}>
        <Shape
          sceneFunc={(context, shape) => {
            context.beginPath();
            context.moveTo(0, 0);
            context.lineTo(side.b, 0); // b
            context.lineTo(side.b, side.a); // a
            context.lineTo(side.d, side.a); // undefined a-left
            context.lineTo(side.d, side.c); // undefined d-top
            context.lineTo(0, side.c); // d
            context.closePath(); // c
            // (!) Konva specific method, it is very important
            context.fillStrokeShape(shape);
          }}
          {...this.props.data.style}
          fill={this.props.data.selected ? this.props.data.style.selectedFill : this.props.data.style.fill}
          ref={node => {this.shape = node;}}
        />
        <Metric {...{height:side.a}} customPositionCoords={{x:side.b+horizontalMetricPadding,y:0}} direction="vertical" position="custom" label="A" rotation={-this.props.data.rotation} wall={walls.a} edge={edges.a} />
        <Metric {...{width:side.b}} direction="horizontal" position="top" label="B" rotation={-this.props.data.rotation} wall={walls.b} edge={edges.b} />
        <Metric {...{height:side.c}} direction="vertical" position="left" label="C" rotation={-this.props.data.rotation} wall={walls.c} edge={edges.c} />
        <Metric {...{width:side.d}} customPositionCoords={{x:0,y:side.c+verticalMetricPadding+5}} direction="horizontal" position="custom" label="D" rotation={-this.props.data.rotation} wall={walls.d} edge={edges.d} />
        <Metric {...{height:side.c-side.a}} customPositionCoords={{x:side.d+horizontalMetricPadding,y:side.a}} direction="vertical" position="custom" label="E" rotation={-this.props.data.rotation} wall={walls.e} edge={edges.e} />
        <Metric {...{width:side.b-side.d}} customPositionCoords={{x:side.d,y:side.a+verticalMetricPadding+5}} direction="horizontal" position="custom" label="F" rotation={-this.props.data.rotation} wall={walls.f} edge={edges.f} />
        <Group x={x} y={y} ref={node => {this.shapeLbl = node}}>
          <Label y={10}>
            <Tag fill={this.props.data.selected ? this.props.data.style.lblSelectedFill : this.props.data.style.lblFill}/>
            <Text text={"Shape " + this.props.data.id} padding={10} fontSize={20} fill="#fff" fontStyle="bold"/>
          </Label>
        </Group>
      </Group>
    )
  }
}

/**
 * Konva U-SHape Class
 * @extends React
 */
class UShape extends React.Component {
  /**
   * Don't know why this is here. It's not in the other shapes. So I've disabled it
   * and if no issues will remove in the future.
   * @param  {event} e    dom event
   * @param  {object} data shape model
   * @return {void}
   */
  onMouseDownHandler(e, data){
    //this.props.onMouseDown(e, data)
  }

  render(){
    const { walls, edges } = this.props.data;
    const verticalMetricPadding = 5
    const horizontalMetricPadding = 15

    var side = {a:90,b:300,c:250,d:200,e:90,f:190}
    if(this.props.data.sides)
      side = this.props.data.sides

    if(this.shapeRef){
      if(this.props.data.selected){
        this.shapeRef.moveToTop()
      }
      this.shapeRef.to({
        rotation: this.props.data.rotation,
        duration: 0.25
      })
    }

    if(this.shapeLbl){
      this.shapeLbl.to({
        rotation: -this.props.data.rotation,
        duration: 0.25
      })
    }

    return (
      <Group {...this.props.data.position} offsetX={side.c/2} offsetY={side.b/2} rotation={this.props.data.rotation} draggable={this.props.draggable} id={this.props.data.id} onDragEnd={(e) => this.props.onDragEnd(e, this.props.data)} onMouseDown={(e) => this.props.onMouseDown(e, this.props.data)} ref={node => {this.shapeRef = node}} >
        <Shape
          sceneFunc={(context, shape) => {
            context.beginPath();
            context.moveTo(0, 0); // starting point
            context.lineTo(side.c, 0); //c
            context.lineTo(side.c, side.b); //b
            context.lineTo((side.c-side.a), side.b); //a
            context.lineTo((side.c-side.a), (side.d-side.f)); // not identified
            context.lineTo(side.e, (side.d-side.f)); // not identified
            context.lineTo(side.e, side.d); // f
            context.lineTo(0, side.d); // e
            context.closePath(); //d
            // (!) Konva specific method, it is very important
            context.fillStrokeShape(shape);
          }}
          {...this.props.data.style}
          fill={this.props.data.selected ? this.props.data.style.selectedFill : this.props.data.style.fill}
          ref={node => {this.shape = node;}}
        />
        <Metric {...{width:side.a}} customPositionCoords={{x:(side.c-side.a),y:side.b+verticalMetricPadding+5}} direction="horizontal" position="custom" label="A" rotation={-this.props.data.rotation} wall={walls.a} edge={edges.a} />
        <Metric {...{width:side.c, height:side.b}} direction="vertical" position="right" label="B" rotation={-this.props.data.rotation} wall={walls.b} edge={edges.b} />
        <Metric {...{width:side.c}}  direction="horizontal" position="top" label="C" rotation={-this.props.data.rotation} wall={walls.c} edge={edges.c} />
        <Metric {...{height:side.d}} customPositionCoords={{x:-horizontalMetricPadding,y:0}} direction="vertical" position="custom" label="D" wallPosition="left" rotation={-this.props.data.rotation} wall={walls.d} edge={edges.d} />
        <Metric {...{width:side.e}} customPositionCoords={{x:0,y:side.d+verticalMetricPadding+5}} direction="horizontal" position="custom" label="E" rotation={-this.props.data.rotation} wall={walls.e} edge={edges.e} />
        <Metric {...{height:side.f}} customPositionCoords={{x:side.e+horizontalMetricPadding,y:(side.d-side.f)}} direction="vertical" position="custom" label="F" rotation={-this.props.data.rotation} wall={walls.f} edge={edges.f} />
        <Metric {...{width:side.c-side.a-side.e}} customPositionCoords={{x:side.e,y:(side.d-side.f+verticalMetricPadding+5)}} direction="horizontal" position="custom" label="G" rotation={-this.props.data.rotation} wall={walls.g} edge={edges.g} />
        <Metric {...{height:side.b-side.f}} customPositionCoords={{x:(side.c-side.a)-horizontalMetricPadding,y:(side.d-side.f)}} direction="vertical" position="custom" label="H" wallPosition="left" rotation={-this.props.data.rotation} wall={walls.h} edge={edges.h} />
        <Group x={0} y={side.d+25} ref={node => {this.shapeLbl = node}}>
          <Label y={10}>
            <Tag fill={this.props.data.selected ? this.props.data.style.lblSelectedFill : this.props.data.style.lblFill}/>
            <Text text={"Shape " + this.props.data.id} padding={10} fontSize={20} fill="#fff" fontStyle="bold"/>
          </Label>
        </Group>
      </Group>
    )
  }
}

export {Measurements};

import React from 'react';
//import Konva from 'konva'
//import logo from './logo.svg';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Measurements} from "./measurements";
import {ColorChooser, StoneSelector} from "./color-chooser";
import {StoneEstimateSummary} from "./esitmate-summary";
import {EdgeAndCornersOptions,
        SinkOptions,
        CutoutsOptions,
        PlumbingOptions,
        RemovalOptions,
        ProductOptions} from "./other-options";
import LocalizedStrings from 'react-localization';

/************************

  Things to help me remember.

  1) The default selected swatch is defined in the StoneSwatchGrid class in the color-choose.js file.


 ************************/



class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      stone: undefined,
      dimension: {total:{sqft:0, sqftBacksplash:0, lftFinshedEdge:0, lftApplianceEdge:0}, shapes:[], hasMinimumSqFt:true},
      edge: undefined,
      corner: undefined,
      sink: undefined,
      sinks:undefined,
      cutout: undefined,
      plumbing: undefined,
      removal: undefined,
      product: undefined,
      isLoaded: false,
      imgDataUrl: null,
      strings: null,
      settings: undefined,
      minimumSqFt: 0
    }
    this.imgDataUrl = null
    this.selectedStoneChangedHandler = this.selectedStoneChangedHandler.bind(this);
    this.selectedStoneCategoryChangeHandler = this.selectedStoneCategoryChangeHandler.bind(this)
    this.setStateChangeHandler = this.setStateChangeHandler.bind(this)
    this.onSqftCalculatedHandler = this.onSqftCalculatedHandler.bind(this)
    this.onBacksplashSqftCalculatedHandler = this.onBacksplashSqftCalculatedHandler.bind(this)
    this.onShapesChangedHandler = this.onShapesChangedHandler.bind(this)
    this.onEdgeLinearFtCalculatedHandler = this.onEdgeLinearFtCalculatedHandler.bind(this)
  }

  /**
   * loads the external data, shapes, strings, colors, stone categories, etc.
   * @return {void}
   */
  componentDidMount() {
    Promise.all([
      fetch("shapes.json"),
      fetch("options.json"),
      fetch("https://www.hardrockstoneworks.com/estimator/api/colors"),
      fetch("https://www.hardrockstoneworks.com/estimator/api/stones"),
      fetch("strings.json"),
      fetch("settings.json"),
    ])
    .then(
        (responses) => Promise.all(responses.map((r) => r.json()))
      )
    .then(
        (results) => {
          this.setState({
            isLoaded: true,
            shapes: results[0],
            options: results[1],
            colors: results[2],
            stones: results[3],
            selectedStone:results[3][0],
            strings: new LocalizedStrings(results[4]),
            settings: results[5],
            minimumSqFt: results[5].minimumSqFt
          })
          window.appComponentDidMount()
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          })
          window.appComponentDidMount()
        }
      )
  }

  /**
   * Updates the estimate summary model for edge linear foot
   * @param  {number} lft total linear foot of the selected edges
   * @param  {string} key 'lftFinshedEdge' or 'lftApplianceEdge'
   * @return {void}
   */
  onEdgeLinearFtCalculatedHandler(lft, key){
    //console.log("onEdgeLinearFtCalculatedHandler", lft, key)
    const dimension = this.state.dimension
    if(lft !== dimension.total[key]){
      dimension.total[key] = lft
      this.setState({dimension: dimension})
    }
  }

  /**
   * Updates the estimate summary model for backsplash
   * @param  {number} sqft total sqft of basksplash for all shapes
   * @return {void}
   */
  onBacksplashSqftCalculatedHandler(sqft){
    const dimension = this.state.dimension
    if(sqft !== dimension.total.sqftBacksplash){
      dimension.total.sqftBacksplash = sqft
      this.setState({dimension: dimension})
    }
  }

  /**
   * Updates the estimate summary model for total sqft
   * @param  {number} sqft total sqft of the shapes
   * @return {void}
   */
  onSqftCalculatedHandler(sqft){
    const dimension = this.state.dimension
    if(sqft !== dimension.total.sqft){
      dimension.total.sqft = sqft
      dimension.hasMinimumSqFt = sqft >= this.state.minimumSqFt
      this.setState({
          dimension: dimension
        })
    }
  }

  /**
   * Updates the estimate summary model for a key
   * @param {string} key  the name of the key in the state
   * @param {any} item the value for the key
   */
  setStateChangeHandler(key, item){
    //console.log(key, item)
    this.setState({
      [key]: item
    })
  }

  /**
   * Updates the estimate summary for the stone
   * @param  {any} item the selected stone information (aka: Color)
   * @return {void}
   */
  selectedStoneChangedHandler(item){
    this.setState({
      stone: item
    })
  }

  /**
   * Updates the estimate summary for the shapes
   * @param  {[type]} shapes
   * @return {void}
   */
  onShapesChangedHandler(shapes){
    const dimension = this.state.dimension
    if(shapes !== dimension.shapes){
      //console.log("onShapesChangedHandler", shapes)
      dimension.shapes = shapes
      this.setState({dimension: dimension})
    }
    else {
      console.log("onShapesChangedHandler", "nothing changed")
    }
  }

  /**
   * Updates the estimate summary for the stone category
   * @param  {event} e
   * @param  {any} item
   * @return {void}
   */
  selectedStoneCategoryChangeHandler(e, item) {
    this.setState({
      selectedStone: item
    })
  }
  /**
   * Html
   * @return {string}
   */
  render() {
    const { error, isLoaded, shapes, options, colors, selectedStone, stones } = this.state;
    if (error) {
      return <div className="loading d-flex align-items-center justify-content-center flex-column"><div className="text-white">Error: {error.message}</div></div>;
    }
    else if (!isLoaded) {
      return <div className="loading d-flex align-items-center justify-content-center flex-column"><div><FontAwesomeIcon icon="circle-notch" size="8x" spin className="text-white"/></div><div className="small mt-3 text-white">LOADING</div></div>;
    }
    else {
      return (
        <div className="App d-flex p-3 position-relative">
          {
            !this.state.dimension.hasMinimumSqFt ? (<div className="text-white bg-danger mb-3 p-3 fixed-top ml-0 mt-0"><FontAwesomeIcon icon="exclamation-triangle" size="1x" className="text-white mr-2"/>{this.state.strings?.formatString(this.state.strings?.errors.minimumSqFtErrorTxt,this.state.minimumSqFt)}</div>) : ("")
          }
          <div className="flex-grow-1-">
            <Measurements shapes={shapes} onSqftCalculated={this.onSqftCalculatedHandler} onBacksplashSqftCalculated={this.onBacksplashSqftCalculatedHandler} onShapesChanged={this.onShapesChangedHandler} onEdgeLinearFtCalculated={this.onEdgeLinearFtCalculatedHandler} localization={this.state.strings.measurement} />
            <StoneSelector items={stones} onChange={this.selectedStoneCategoryChangeHandler} localization={this.state.strings.stone} />
            <ColorChooser products={colors} stoneCategory={selectedStone} onSelectedStoneChanged={this.selectedStoneChangedHandler} localization={this.state.strings.color} />
            <EdgeAndCornersOptions edgeOptions={options.edge} cornerOptions={options.corner} onSelectEdgeChanged={this.setStateChangeHandler} onSelectCornerChanged={this.setStateChangeHandler} localization={{"edge":this.state.strings.edge, "corner":this.state.strings.corner}} />
            <SinkOptions options={options.sink} sinks={options.sinks} onClick={this.setStateChangeHandler} localization={this.state.strings.sink} />
            <CutoutsOptions options={options.cutout} onClick={this.setStateChangeHandler} localization={this.state.strings.cutout} />
            <PlumbingOptions options={options.plumbing} onClick={this.setStateChangeHandler} localization={this.state.strings.plumbing} />
            <RemovalOptions options={options.removal} onClick={this.setStateChangeHandler} dimension={this.state.dimension} localization={this.state.strings.removal} />
            <ProductOptions options={options.product} onClick={this.setStateChangeHandler} localization={this.state.strings.product} />
            <div className="mt-4">
            <hr/>
            <b>Important Disclaimer:</b> {this.state.strings.disclaimer.info}
            </div>
          </div>
          <div className="estimator-summary-container sticky-top col-lg-3">
            <StoneEstimateSummary {...this.state} imgDataUrl={this.imgDataUrl} localization={this.state.strings.estimateSummary} />
          </div>
        </div>
      )
    }
  }
}

export default App;

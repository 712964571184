import React from 'react';
import {SelectList, NumberChanger} from "./html-elements";
import {Modal} from "./modal"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * Base Component for Option classes
 * @extends React
 */
class OptionComponent extends React.Component {
  constructor(props){
    super(props)
    this.currencyFormater = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
  }

  /**
   * Takes a string with a predefined pattern and build a unorderd list
   * @param  {string} info The formated string
   * @return {string}      The unorder html
   */
  formatInfo(info){
    const textArr = info.split("|")
    if( textArr.length > 1){
      return (
        <ul className="pl-3">
        {
          textArr.map((line, index) => {
            return (<li key={index}>{line}</li>)
          })
        }
        </ul>
      )
    }
    else {
      return info
    }
  }
}

/**
 * Renders the Edge and Corner Section
 * @extends React
 */
export class EdgeAndCornersOptions extends React.Component {
  render() {
    return (
      <div className="estimator-section">
        <div>
          <h2>{ this.props.localization.edge.title } <span className="d-none">& Edges</span></h2>
          <hr className="section-seperator" />
        </div>
        <div>
          <EdgeOptions options={this.props.edgeOptions} onClick={this.props.onSelectEdgeChanged} localization={this.props.localization.edge} />
          <CornerOptions options={this.props.cornerOptions} onClick={this.props.onSelectCornerChanged} localization={this.props.localization.corner} />
        </div>
      </div>
    )
  }
}

/**
 * Class for the Edge Options
 * @extends OptionComponent
 */
export class EdgeOptions extends OptionComponent {
    constructor(props){
      super(props)
      this.state = {
        selected:undefined
      }
    }

    /**
     * Sets the default selected item
     * @return {void}
     */
    componentDidMount(){
      this.props.options.forEach((option) => {
        if(option.select)
          this.onClickHandler(option)
      })
    }

    /**
     * Handle the click event for a edge option then sends it up the tree.
     * @param  {object} option The model of the clicked Edge
     * @return {void}
     */
    onClickHandler(option){
      const selected = option //option.id !== this.state.selected?.id ? option : undefined
      this.props.onClick('edge', selected)
      this.setState({
        selected: selected
      })
    }

    /**
     *
     * @return {string}
     */
    render() {
      return (
        <div className="estimator-section edges">
          { this.props.localization.info ? (<p>{this.props.localization.info}</p>) : ("")}
          { this.props.localization.subTitle ? (<div className="h6">{ this.props.localization.subTitle }</div>) : ("")}
          <div className="d-flex flex-wrap">
          {
            this.props.options.map((option, index) => {
              return (
                <div key={index} className={this.state.selected && this.state.selected.id === option.id ? 'text-center option-container nowidth selected' : 'text-center option-container nowidth'} onClick={(e) => this.onClickHandler(option)}>
                  <div className="option">
                  <div><img src={option.imageSrc} alt={option.name}/></div>
                  <div className="name">{option.name}</div>
                  <div className="mt-auto font-italic small pt-1">{ option.price > 0 ? (this.currencyFormater.format(option.price) + " lft.") : ("No charge")}</div>
                  { option.info.length > 0 ? (<div className="mb-3 text-left info">{ this.formatInfo(option.info) }</div>) : ("") }
                  </div>
                </div>
              )
            })
          }
          </div>
        </div>
      )
    }
}

/**
 * Class for the Corner Options.
 * @extends OptionComponent
 */
export class CornerOptions extends OptionComponent {
  constructor(props){
    super(props)
    this.state = {
      selected:[]
    }
  }

  /**
   * Handle the click event for a corner option then sends it up the tree.
   * @param  {any} option corner item model
   * @return {void}
   */
  onClickHandler(option){
    var selected = this.state.selected
    if(!selected.includes(option))
      selected.push(option)
    else {
      selected = selected.filter((item) => { return item.id !== option.id })
    }
    this.props.onClick('corner', selected)
    this.setState({
      selected: selected
    })
  }

  /**
   *
   * @return {string}
   */
  render() {
    return (
      <div className="estimator-section corners">
        <div>
        <h2>{this.props.localization.title}</h2>
        <hr className="section-seperator" />
      </div>
        { this.props.localization.info ? (<p>{this.props.localization.info}</p>) : ("") }
        <div className="d-flex- flex-wrap d-none">
        {
          this.props.options.map((option, index) => {
            return (
              <div key={index} className={this.state.selected && this.state.selected.includes(option) ? 'text-center option-container selected' : 'text-center option-container'} onClick={(e) => this.onClickHandler(option)}>
                <div className="option">
                <div><img src={option.imageSrc} alt={option.name} /></div>
                <div className="mt-4"><span className="name">{option.label}</span> {option.price > 0 ? (<i> - ${option.price} each</i>) : <i> - No Charge</i>}</div>
                { option.info.length > 0 ? (<div className="mb-3 text-left info">{ this.formatInfo(option.info) }</div>) : ("") }
                </div>
              </div>
            )
          })
        }
        </div>
      </div>
    )
  }
}

/**
 * Class for the Sink Options
 * @extends OptionComponent
 */
export class SinkOptions extends OptionComponent {
  constructor(props){
    super(props)
    this.state = {
      selected:undefined
    }
    this.selectedSinksCache = []
    this.currencyFormater = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
    this.onSinkSelectionChange = this.onSinkSelectionChange.bind(this)
  }

  /**
   * Handle the click event for a sinks option then sends it up the tree. This event
   * is triggered in a sub component.
   * @param  {array} selected An array of selected sinks
   * @return {void}
   */
  onSinkSelectionChange(selected){
    this.selectedSinksCache = selected
    this.props.onClick('sinks', selected)
  }

  /**
   * Selects the default item
   * @return {void}
   */
  componentDidMount(){
    this.props.options.forEach((option) => {
      if(option.select)
        this.onClickHandler(option)
    })
  }

  /**
   * Handle the click event for a sink option then sends it up the tree.
   * @param  {any} option A sink option model
   * @return {[type]}
   */
  onClickHandler(option){
    const selected = option //option.id !== this.state.selected?.id ? option : undefined
    this.props.onClick('sink', selected)
    this.setState({
      selected: selected
    })
  }

  /**
   *
   * @return {string}
   */
  render() {
    return (
      <div className="estimator-section sink">
        <h2>{this.props.localization.title}</h2>
        <hr className="section-seperator" />
        { this.props.localization.info ? (<p>{this.props.localization.info}</p>) : ("")}
        { this.props.localization.subTitle ? (<div className="h6">{ this.props.localization.subTitle }</div>) : ("")}
        <div className="d-flex flex-wrap">
        {
          this.props.options.map((option, index) => {
            return (
              <div key={index} className={this.state.selected && this.state.selected.id === option.id ? 'text-center option-container selected' : 'text-center option-container'} onClick={(e) => this.onClickHandler(option)}>
                <div className="option">
                  <div><img src={option.imageSrc} className="img-fluid" alt={option.name} /></div>
                  <div className="pb-2 name">{option.name}</div>
                  { option.info.length > 0 ? (<div className="mb-3 text-left info">{ this.formatInfo(option.info) }</div>) : ("") }
                  <div className="mt-auto font-italic small d-none">{ option.price > 0 ? "Starting at: " + this.currencyFormater.format(option.price) : ""}</div>
                  <div className="text-left small text-muted text-center d-none">{ this.formatInfo(option.info) }</div>
                </div>
              </div>
            )
          })
        }
        </div>
        {
          this.state.selected?.id === 2 ?
          (
            <div>
            <h2>{this.props.localization.types.title}</h2>
            <hr className="section-seperator secondary" />
            { this.props.localization.types.info ? (<p>{this.props.localization.types.info}</p>) : ("")}
            <SinkTypes sinks={this.props.sinks} onSinkSelectionChange={this.onSinkSelectionChange} selectedSinks={this.selectedSinksCache} localization={this.props.localization}/>
            </div>
          )
          :
          ("")
        }
      </div>
    )
  }
}

/**
 * Class for the Sink Types Options
 * @extends OptionComponent
 */
export class SinkTypes extends OptionComponent {
  constructor(props) {
    super (props)
    this.state = {
      selected: undefined,
      sinks: new Map(),
      imageModal:{
        show: false,
        src: "",
        title: "",
        loading: false
      }
    }
    this.sinkSelectedOptionsForType = new Map()
    this.onSinkSelectionChange = this.onSinkSelectionChange.bind(this)
    this.onExpandImageClickHandler = this.onExpandImageClickHandler.bind(this)
    this.closeImageModalHandler = this.closeImageModalHandler.bind(this)
    this.onModalImageLoadHandler = this.onModalImageLoadHandler.bind(this)
  }

  /**
   * [onModalImageLoadHandler description]
   * @return {void}
   */
  onModalImageLoadHandler(){
    const imageModal = this.state.imageModal
    imageModal.loading = false
    this.setState({
      imageModal: imageModal
    })
  }

  /**
   * Opens the image model
   * @param  {string} src Image src to load
   * @param  {string} title Title of the modal
   * @return {void}
   */
  onExpandImageClickHandler(src, title){
    console.log(src,title)
    this.setState({
      imageModal:{
        show: true,
        loading: true,
        src: src,
        title: title
      }
    })
  }

  /**
   * Closes the image modal
   * @return {void}
   */
  closeImageModalHandler(){
    this.setState({
      imageModal:{
        show:false
      }
    })
  }

  /**
   * Passes the selected sinks up the tree.
   * @param  {array} selected Array of selected sinks for this type.
   * @return {void}
   */
  onSinkSelectionChange(selected){
    const sinks = this.state.sinks
    sinks.set(this.state.selected.id, {id:this.state.selected.id ,name:this.state.selected.name, selected:selected})
    this.setState({sinks: sinks})
    const sinksArray = Array.from(sinks,([name, value]) => (value))
    this.props.onSinkSelectionChange(sinksArray)
  }

  /**
   * [onClickHandler description]
   * @param  {any} option The sink type model
   * @return {void}
   */
  onClickHandler(option){
    if(option.id !== this.state.selected?.id )
      this.setState({selected: option})
  }

  /**
   * Creates the html that displays the number of sinks selected in the type.
   * @param  {number} id [description]
   * @return {string}
   */
  formatTypeSelectedInfo(id){
    const type = this.state.sinks.get(id)
    if(type && type.selected.length > 0){
      var qty = 0
      var price = 0
      type.selected.forEach((item, index)=>{
        qty += item.qty
        price += item.qty * item.price
      })
      return (<div className="text-center small">QTY {qty} | {this.currencyFormater.format(price)}</div>)
    }
    else {
      return (<div className="text-center small text-light">QTY 0 | {this.currencyFormater.format(0)}</div>)
    }
  }

  /**
   * Sets the first item in the array as selected.
   * @return {void}
   */
  componentDidMount() {
    const sinks = new Map()
    this.props.selectedSinks.forEach((item, index) => {
      sinks.set(item.id, item)
    })
    this.setState({selected: this.props.sinks.types[0], sinks: sinks})
  }

  /**
   *
   * @return {string}
   */
  render() {
    return (
      <div className="estimator-section pl-3 bg-light border pt-3 sink-types">
        <div className="d-flex flex-wrap">
          {
            this.props.sinks.types.map( (option, index) => {
              return (
                  <div key={index} className={this.state.selected && this.state.selected.id === option.id ? 'text-center option-container selected col-lg--4' : 'text-center option-container col-lg--4'} onClick={(e) => this.onClickHandler(option)}>
                    <div className="option thinner bg-white">
                      <div><img src={option.imageSrc} className="img-fluid" alt={option.name} /></div>
                      <div className="name mt-auto">{option.name.toUpperCase()}</div>
                      <div className="mb-2">
                      {
                          this.formatTypeSelectedInfo(option.id)
                      }
                      </div>
                      { option.info.length > 0 ? (<div className="mb-3 text-left info">{ this.formatInfo(option.info) }</div>) : ("") }
                      <div className="mt-auto font-italic small d-none">{ option.price > 0 ? "Starting at: " + this.currencyFormater.format(option.price) : ""}</div>
                      <div className="text-left small text-muted text-center d-none">{ this.formatInfo(option.info) }</div>
                    </div>
                  </div>
              )
            })
          }
        </div>
        {
          this.state.selected !== undefined ?
          (
            <div>
              <h2>{this.props.localization.sinks.title}</h2>
              <hr className="section-seperator secondary" />
              { this.props.localization.sinks.info ? (<p>{this.props.localization.sinks.info}</p>) : ("")}
              <SinkTypeOptions sinks={this.props.sinks.types.find(t => t.id === this.state.selected.id)} onSelectionChange={this.onSinkSelectionChange} localization={this.props.localization} onExpandImageClick={this.onExpandImageClickHandler} />
            </div>
          )
          :
          ("")
        }
        <div style={{display: this.state.imageModal.loading ? "none" : "block"}}>
          <Modal show={this.state.imageModal.show} onClose={this.closeImageModalHandler} close={"Close"} title={this.state.imageModal.title} >
            <img src={this.state.imageModal.src} className="img-fluid" alt={this.state.imageModal.title} onLoad={this.onModalImageLoadHandler} />
          </Modal>
        </div>
        <div style={{display: this.state.imageModal.loading ? "block" : "none"}}>
          <div className="loading-expanded-img d-flex align-items-center justify-content-center flex-column">
            <div><FontAwesomeIcon icon="image" spin size="8x" className="text-white"/></div><div className="small mt-3 text-white">LOADING IMAGE</div>
              <div className="overlay-close-btn" onClick={this.onModalImageLoadHandler}>
                <FontAwesomeIcon icon="slash" size="2x" className="text-white"/>
                <FontAwesomeIcon flip="vertical" transform="left-20" size="2x" icon="slash" className="text-white"/>
              </div>
          </div>
        </div>
      </div>
    )
  }
}

/**
 * Class for the Sink Types Sink Options
 * @extends OptionComponent
 */
export class SinkTypeOptions extends OptionComponent {
  constructor(props) {
    super (props)
    this.state = {
      selected: []
    }
    this.updateSelected = this.updateSelected.bind(this)
    this.onClickHandler = this.onClickHandler.bind(this)
    this.onQtyChangeHandler = this.onQtyChangeHandler.bind(this)
    this.restore = this.restore.bind(this)
  }

  /**
   * adds or removes a sink to the selected array based upon the qty value.
   * @param  {object} option the sink model
   * @return {void}
   */
  updateSelected(option){
    var selected = this.state.selected
    if(!selected.includes(option) && option.qty > 0 )
      selected.push(option)
    else if (option.qty <= 0){
      selected = selected.filter((item) => { return item.id !== option.id })
    }
    this.setState({
      selected: selected
    })
    return selected
  }

  /**
   * Updates the selected array and sends it up the tree
   * @param  {obejct} option sink model
   * @return {void}
   */
  onClickHandler(option){
    const selected = this.updateSelected(option)
    this.props.onSelectionChange(selected)
  }

  /**
   * Updates the sink model with the qty. It handles the NumberChanger component change event.
   * @param  {number} value  qty
   * @param  {object} option sink model
   * @return {void}
   */
  onQtyChangeHandler(value, option){
    option.qty = value
    this.onClickHandler(option)
  }

  /**
   * Adds the selected options to the selected array. This restores the
   * UI to reflect the sinks model for the type.
   * @return {void}
   */
  restore(){
    const selected = []
    this.props.sinks.options.forEach((option) => {
      if(option.qty > 0)
        selected.push(option)
    })
    this.setState({selected: selected})
  }

  /**
   * Restores the state on load
   * @return {void}
   */
  componentDidMount(){
    this.restore()
  }

  /**
   * If the sink type has change it restore the sinks state.
   * @param  {object} prevProps
   * @param  {object} prevState
   * @return {void}
   */
  componentDidUpdate(prevProps, prevState){
    if(this.props.sinks.id !== prevProps.sinks.id) {
      this.restore()
    }
  }

  /**
   *
   * @return {string}
   */
  render() {
    return (
      <div className="estimator-section pl-3 mr-3 sink-type-options pt-3">
        <div className="d-flex flex-wrap">
          {
            this.props.sinks.options.map( (option, index) => {
              return (
                <div key={index} className={this.state.selected && this.state.selected.includes(option) && option.qty > 0 ? 'text-center col-lg-3 col-4 option-container selected p-0' : 'text-center col-lg-3 col-4 option-container p-0'}>
                  <div className="option bg-white defaul-cursor">
                    <div><img src={option.imageSrc} className="img-fluid pointer-cursor" alt={option.name} onClick={(e) => this.props.onExpandImageClick(option.imageSrc, option.name)} /></div>
                    <div className="name text-left mt-auto">{option.name.toUpperCase()}</div>
                    <div className="text-left info">{ (option.shape ? option.shape : "") + " " + (option.basin ? option.basin : "") }</div>
                    { option.info.length > 0 ? (<div className="text-left info">{ this.formatInfo(option.info) }</div>) : ("") }
                    { option.type?.length > 0 ? (<div className="text-left property d-none"><span>Type:</span>{ this.formatInfo(option.type) }</div>) : ("") }
                    { option.shape?.length > 0 ? (<div className="text-left property d-none"><span className="label">Shape:</span>{ this.formatInfo(option.shape) }</div>) : ("") }
                    { option.basin?.length > 0 ? (<div className="text-left property d-none"><span className="label">Split:</span>{ this.formatInfo(option.basin) }</div>) : ("") }
                    { option.material?.length > 0 ? (<div className="text-left property"><span className="label">Material:</span>{ this.formatInfo(option.material) }</div>) : ("") }
                    { option.color?.length > 0 ? (<div className="text-left property"><span className="label">Color:</span>{ this.formatInfo(option.color) }</div>) : ("") }
                    { option.finish?.length > 0 ? (<div className="text-left property"><span className="label">Finish:</span>{ this.formatInfo(option.finish) }</div>) : ("") }
                    { option.sku.length > 0 ? (<div className="text-left property d-none"><span className="label">SKU #:</span>{ this.formatInfo(option.sku) }</div>) : ("") }
                    { option.model.length > 0 ? (<div className="text-left property"><span className="label">Model #:</span>{ this.formatInfo(option.model) }</div>) : ("") }
                    <div className="mt-1 font-italic font-weight-bold text-left mb-2">{ option.price > 0 ? this.currencyFormater.format(option.price) : ""}</div>
                    <NumberChanger value={option.qty} onChange={this.onQtyChangeHandler} obj={option}/>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}

/**
 * Class for the Cutouts Options
 * @extends OptionComponent
 */
export class CutoutsOptions extends OptionComponent {
  constructor(props){
    super(props)
    this.state = {
      selected:[]
    }
    this.onClickHandler = this.onClickHandler.bind(this)
    this.onQtyChangeHandler = this.onQtyChangeHandler.bind(this)
    this.currencyFormater = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
  }

  /**
   * Sets the default selected array.
   * @return {void}
   */
  componentDidMount(){
    this.props.options.forEach((option) => {
      if(option.select)
        this.onQtyChangeHandler(option.qty, option)
    })
  }

  /**
   * Adds or removes the cutout to the selected array based upon the qty. Sends it up the tree.
   * @param  {object} option Cutout model
   * @return {void}
   */
  onClickHandler(option){
    var selected = this.state.selected
    if(!selected.includes(option) && option.qty > 0 )
      selected.push(option)
    else if (option.qty <= 0){
      selected = selected.filter((item) => { return item.id !== option.id })
    }
    this.props.onClick('cutout', selected)
    this.setState({
      selected: selected
    })
  }

  /**
   * Sets the qty of the model. Handles the NumberChanger change event.
   * @param  {number} value  qty
   * @param  {object} option Cutout model
   * @return {void}
   */
  onQtyChangeHandler(value, option){
    option.qty = value
    this.onClickHandler(option)
  }

  /**
   *
   * @return {string}
   */
  render() {
    return (
      <div className="estimator-section cutouts">
        <h2>{this.props.localization.title}</h2>
        <hr className="section-seperator" />
        { this.props.localization.info ? (<p>{this.props.localization.info}</p>) : ("")}
        { this.props.localization.subTitle ? (<div className="h6">{ this.props.localization.subTitle }</div>) : ("")}
        <div className="d-flex flex-wrap">
        {
          this.props.options.map((option, index) => {
            return (
              <div key={index} className={this.state.selected && this.state.selected.includes(option) && option.qty > 0 ? 'text-center selected option-container' : 'text-center option-container'} >
                <div className="option">
                <div><img src={option.imageSrc} alt={option.name} /></div>
                <div className="my-4"><span className="name">{option.name}</span> {option.price > 0 ? (<i> - {this.currencyFormater.format(option.price)} each</i>) : <i> - No Charge</i>}</div>
                { option.info.length > 0 ? (<div className="mb-3 text-left info">{ this.formatInfo(option.info) }</div>) : ("") }
                <NumberChanger value={option.qty} onChange={this.onQtyChangeHandler} obj={option}/>
                </div>
              </div>
            )
          })
        }
        </div>
      </div>
    )
  }
}

/**
 * Class for the Plumbing Options
 * @extends OptionComponent
 */
export class PlumbingOptions extends OptionComponent {
  constructor(props){
    super(props)
    this.state = {
      disconnect: undefined,
      connect: undefined
    }
    this.currencyFormater = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
  }

  /**
   * Sets the default state from the model
   * @return {void}
   */
  componentDidMount(){
    // Basiclly this sets the state of disconnect then waits unit it's set
    // then sets the state of connect. Waits then calls the event handler that
    // walks it up the tree.
    // This seems like a hack because of the model. Maybe think about changing the model.
    this.props.options.disconnect.forEach((option) => {
      if(option.select)
        this.setState({
          disconnect: option
        },
        () => {
          this.props.options.connect.forEach((option) => {
            if(option.select)
              this.setState({
                connect: option
              },
              () => {
                this.props.onClick('plumbing', {disconnect:{...this.state.disconnect}, connect:{...this.state.connect}})
              }
            )
          })
        }
      )
    })
  }

  /**
   * Sets the disconnect option value and sends it up the tree.
   * @param  {object} option disconnect model
   * @return {void}
   */
  onDisconnectClickHandler(option){
    var options = {}
    const selected = option //option.id !== this.state.disconnect?.id ? option : undefined
    if( selected && !this.state.connect )
      options = {disconnect:{...selected}}
    if( selected && this.state.connect  )
      options = {disconnect:{...selected}, connect:{...this.state.connect}}
    if( !selected && this.state.connect  )
      options = {connect:{...this.state.connect}}
    console.log("onDisconnectClickHandler",options)
    this.props.onClick('plumbing', options)
    this.setState({
      disconnect: selected
    })
  }

  /**
  * Sets the connect option value and sends it up the tree.
  * @param  {object} option connect model
  * @return {void}
   */
  onConnectClickHandler(option){
    var options = {}
    const selected = option //option.id !== this.state.connect?.id ? option : undefined
    if( selected && !this.state.disconnect )
      options = {connect:{...selected}}
    if( selected && this.state.disconnect  )
      options = {connect:{...selected}, disconnect:{...this.state.disconnect}}
    if( !selected && this.state.disconnect  )
      options = {disconnect:{...this.state.disconnect}}
    this.props.onClick('plumbing', options)
    console.log("onConnectClickHandler",options)
    this.setState({
      connect: selected
    })
  }

  /**
   *
   * @return {string}
   */
  render() {
    return (
      <div className="estimator-section plumbing">
        <h2>{this.props.localization.title}</h2>
        <hr className="section-seperator" />
        { this.props.localization.info ? (<p>{this.props.localization.info}</p>) : ("")}
        <div className="h6">{this.props.localization.title}</div>
        { this.props.localization.disconnect.info ? (<p>{this.props.localization.disconnect.info}</p>) : ("")}
        { this.props.localization.disconnect.subTitle ? (<div className="h6">{ this.props.localization.disconnect.subTitle }</div>) : ("")}
        <div className="d-flex flex-wrap">
        {
          this.props.options.disconnect.map((option, index) => {
            return (
              <div key={index} className={this.state.disconnect && this.state.disconnect.id === option.id ? 'text-center option-container selected' : 'text-center option-container'} onClick={(e) => this.onDisconnectClickHandler(option)}>
                <div className="option">
                <div className="d-none"><img src={option.imageSrc} alt={option.name} /></div>
                <div className="text-left font-weight-bold name">{option.name}</div>
                <div className="text-left info">{ this.formatInfo(option.info) }</div>
                <div className="mt-auto font-italic small">{ option.price > 0 ? "Cost: " + this.currencyFormater.format(option.price) : ""}</div>
              </div>
              </div>
            )
          })
        }
        </div>
        { this.props.localization.connect.info ? (<p>{this.props.localization.connect.info}</p>) : ("")}
        { this.props.localization.connect.subTitle ? (<div className="h6">{ this.props.localization.connect.subTitle }</div>) : ("")}
        <div className="d-flex flex-wrap">
        {
          this.props.options.connect.map((option, index) => {
            return (
              <div key={index} className={this.state.connect && this.state.connect.id === option.id ? 'text-center selected option-container' : 'text-center option-container'} onClick={(e) => this.onConnectClickHandler(option)}>
                <div className="option">
                <div className="d-none">><img src={option.imageSrc} alt={option.name} /></div>
                <div className="text-left font-weight-bold name">{option.name}</div>
                <div className="text-left info">{ this.formatInfo(option.info) }</div>
                <div className="mt-auto font-italic small">{ option.price > 0 ? "Cost: " + this.currencyFormater.format(option.price) : ""}</div>
                </div>
              </div>
            )
          })
        }
        </div>
      </div>
    )
  }
}

/**
 * [RemovalSqFtOptions description]
 * @type {Array}
 */
const RemovalSqFtOptions = [{value:7.00, price:7.00, name:"Laminate", label:"Laminate - $7.00 / sq. ft."}, {value:10.00, price:10.00, name:"Stone or Title", label:"Stone or Title - $10.00 / sq. ft."}]

/**
 * Class for the Removal Options
 * @extends OptionComponent
 */
export class RemovalOptions extends OptionComponent {
  constructor(props){
    super(props)
    this.state = {
      selected:undefined
    }
    this.onChangeCountertopType = this.onChangeCountertopType.bind(this)
    this.onChangeCountertopSqFt = this.onChangeCountertopSqFt.bind(this)
  }

  /**
   * Set the default seleted
   * @return {void}
   */
  componentDidMount(){
    this.props.options.forEach((option) => {
      if(option.select)
        this.onClickHandler(option)
    })
  }

  /**
   * Sets the selected removal option and sends it up the tree.
   * @param  {object} option Removal model
   * @return {void}
   */
  onClickHandler(option){
    const selected = option //option.id !== this.state.selected?.id ? option : undefined
    // remove countertop type for DIY else add it. I could use the price logic as the
    // condition.
    if( selected !== undefined ) {
      if(option.id === 1){
        selected.countertop = undefined
      }
      else {
        selected.countertop = RemovalSqFtOptions[0]
      }
    }
    this.setState({
      selected: selected
    })
    this.props.onClick('removal', selected)
  }

  /**
   * Handles the type of counter top to be removed.
   * @param  {event} e      event
   * @param  {object} option RemovalSqFtOption model
   * @return {void}
   */
  onChangeCountertopType(e, option){
    const selected = this.state.selected
    if(option.value > 0){
      const sqft = selected.countertop?.qty ? selected.countertop.qty : 0
      option.qty = sqft
      selected.countertop = option
    }
    else
      selected.countertop = undefined
    this.props.onClick('removal', selected)
    this.setState({
      selected: selected
    })
  }

  /**
   * Handles the sqft of the counter to remove. This overides the calculated sqft of the shapes.
   * @param  {number} sqft
   * @return {void}
   */
  onChangeCountertopSqFt(sqft){
    console.log(sqft)
    const selected = this.state.selected
    if(selected.countertop)
      selected.countertop.qty = sqft
    else
      selected.countertop = {qty: sqft}
    this.props.onClick('removal', selected)
  }

  /**
   *
   * @return {string}
   */
  render() {
    //console.log("REMOVAL",this.props.dimension.total.sqft, this.state.selected?.countertop?.qty )
    return (
      <div className="estimator-section removal">
        <h2>{this.props.localization.title}</h2>
        <hr className="section-seperator" />
        { this.props.localization.info ? (<p>{this.props.localization.info}</p>) : ("")}
        { this.props.localization.subTitle ? (<div className="h6">{ this.props.localization.subTitle }</div>) : ("")}
        <div className="d-flex flex-wrap">
        {
          this.props.options.map((option, index) => {
            return (
              <div key={index} className={this.state.selected && this.state.selected.id === option.id ? 'text-center selected option-container' : 'text-center option-container'} onClick={(e) => this.onClickHandler(option)}>
                <div className="option">
                <div className="d-none"><img src={option.imageSrc} alt={option.name} /></div>
                <div className="font-weight-bold text-left name">{option.name}</div>
                <p className="text-left pb-3 info">{ this.formatInfo(option.info) }</p>
                <div className="mt-auto font-italic small">{ option.id > 1 ? <span>{this.props.localization.startingPrice}</span> : ""}</div>
                </div>
              </div>
            )
          })
        }
        </div>
        {
          this.state.selected?.id === 2 ? (<div className="mt-4"><p>What material is your existing countertop?</p><SelectList defaultValue={this.state.selected.countertop?.price} options={RemovalSqFtOptions} onChange={this.onChangeCountertopType} listClass="custom-select col-lg-3" /></div>) : ("")
        }
        {
          this.state.selected?.id === 2 ? (<div className="mt-3"><RemovalSqFtCalculator sqft={ this.state.selected?.countertop?.qty > 0 ? this.state.selected.countertop.qty : this.props.dimension.total.sqft } unitPrice={this.state.selected.countertop?.price} onChange={this.onChangeCountertopSqFt} localization={this.props.localization} /></div>) : ("")
        }
      </div>
    )
  }
}

/**
 * Class for the Product Options
 * @extends OptionComponent
 */
export class ProductOptions extends OptionComponent {
  constructor(props){
    super(props)
    this.state = {
      selected:[]
    }
    this.onClickHandler = this.onClickHandler.bind(this)
    this.onQtyChangeHandler = this.onQtyChangeHandler.bind(this)
  }

  /**
   * Sets the default selected array
   * @return {void}
   */
  componentDidMount(){
    this.props.options.forEach((option) => {
      if(option.qty > 0)
        this.onQtyChangeHandler(option.qty, option)
    })
  }

  /**
   * Adds or removes a prodcut from the selected array based upon it's qty. Sends it up the tree.
   * @param  {object} option Product model
   * @return {void}
   */
  onClickHandler(option){
    var selected = this.state.selected
    if(!selected.includes(option) && option.qty > 0 )
      selected.push(option)
    else if (option.qty <= 0){
      selected = selected.filter((item) => { return item.id !== option.id })
    }
    this.props.onClick('product', selected)
    this.setState({
      selected: selected
    })
  }

  /**
   * Sets the value of the product. Handles the NumberCahnger change event.
   * @param  {number} value  qty
   * @param  {object} option Product model
   * @return {void}
   */
  onQtyChangeHandler(value, option){
    option.qty = value
    this.onClickHandler(option)
  }

  /**
   *
   * @return {string}
   */
  render() {
    return (
      <div className="estimator-section products">
        <h2>{this.props.localization.title}</h2>
        <hr className="section-seperator" />
        { this.props.localization.info ? (<p>{this.props.localization.info}</p>) : ("")}
        { this.props.localization.subTitle ? (<div className="h6">{ this.props.localization.subTitle }</div>) : ("")}
        <div className="d-flex flex-wrap products">
        {
          this.props.options.map((option, index) => {
            return (
              <div key={index} className={this.state.selected && this.state.selected.includes(option) && option.qty > 0 ? 'text-center option-container selected' : 'text-center option-container'}>
                <div className="option">
                <div><img src={option.imageSrc} alt={option.name} className="img-fluid" style={{maxHeight:300}}/></div>
                <div className="name font-weight-bold">{option.name}</div>
                <div className="my-4">{option.price > 0 ? (<b>${option.price}<span className="small pl-1 font-italic">+ tax</span></b>) : <b>No Charge</b>}</div>
                {
                 option.info ? (<p className="text-left info">{ this.formatInfo(option.info) }</p>) : ("")
                }
                <NumberChanger value={option.qty} onChange={this.onQtyChangeHandler} obj={option}/>
                </div>
              </div>
            )
          })
        }
        </div>
      </div>
    )
  }
}

/**
 * Class for the Removal SqFt Calculator
 * @extends React
 */
class RemovalSqFtCalculator extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      sqft: this.props.sqft
    }
    this.currencyFormater = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
    this.sqftModified = false
  }

  /**
   * Checks and updates the sqft value and sends it up the tree.
   * @param  {number} number sqft
   * @return {void}
   */
  onChangeHandler(number){
    this.sqftModified = true
    if(isNaN(number)) {
      number = number.replace(/\D/g,'')
      if(number.toString().length === 0)
        number = 0
    }
    else if( !number.toString().length > 0) {
      number = 0
    }
    number = parseInt(number, "10")
    number = number < 0 ? 0 : number
    this.setState({
      sqft: number
    })
    this.props.onChange(number)
  }
  componentWillUnmount() {
    this.sqftModified = false
  }
  /**
   * Sets updates the sqft value if it changed.
   * @param  {object} prevProps
   * @param  {object} prevState
   * @return {void}
   */
  componentDidUpdate(prevProps, prevState){
    if(this.state.sqft !== this.props.sqft && !this.sqftModified)
      this.setState({sqft: this.props.sqft})
  }

  /**
   *
   * @return {string}
   */
  render() {
    return (
      <div>
      <p>Confirm your existing countertop square footage. We’ve pre-populated below based on your measurements above.</p>
      <label className="small text-muted">Countertop Sq. Ft.</label>
      <div className="d-flex align-items-center">
      <input className="form-control col-lg-3" value={this.state.sqft} onChange={(e) => this.onChangeHandler(e.target.value)} />
      <div className="ml-1">× {this.currencyFormater.format(this.props.unitPrice > 0 ? this.props.unitPrice : 0) } / sq. ft. = { this.currencyFormater.format(((this.props.unitPrice > 0 ? this.props.unitPrice : 0) * this.state.sqft)) }</div>
      </div>
      {
        this.state.sqft === 0 ? (<small className="text-danger text-italic">{this.props.localization.sqftError}</small>) : ("")
      }

      </div>
    )
  }
}

import React from 'react';
import {Modal, ConfrimModal} from "./modal"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReCAPTCHA from "react-google-recaptcha";
import {cloneDeep} from 'lodash';

/**
 * Used to display a line item in the estimate summary.
 * @param {object} props
 */
export const SummaryDetail = props => {
    return (
      <div className={props.className + " d-flex justify-content-between estimator-detail px-2"}>
        <div className={props.labelClassName}>{props.label}</div><div className={props.valueClassName}>{props.value}</div>
      </div>
    )
}

/**
 * Esitmate summary UI component
 * @type {Object}
 */
export class StoneEstimateSummary extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      subtotal: 0,
      isContactModalOpen: false,
      isPurchaseModalOpen: false,
      estimateDataSent: false,
      isSendingData: false,
      isFeedbackModalOpen: false,
      form: {email:"", name:"", recaptcha:"", phone:"", additionalInfo:""},
      alert:{title: "", msg: "", errNumber: "", errText:""},
      feedbackForm:{},
      purchaseForm:{email:"", name:"", recaptcha:"", phone:"", additionalInfo:"", amount: 0}
    }
    this.summary = {}
    this.numberFormater = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
    this.showContactModal = this.showContactModal.bind(this)
    this.hideContactModal = this.hideContactModal.bind(this)
    this.showPurchaseModal = this.showPurchaseModal.bind(this)
    this.hidePurchaseModal = this.hidePurchaseModal.bind(this)
    this.sendEstimateData = this.sendEstimateData.bind(this)
    this.sendPurchaseData = this.sendPurchaseData.bind(this)
    this.closeAlertModal = this.closeAlertModal.bind(this)
    this.showSendingDataOverlay = this.showSendingDataOverlay.bind(this)
    this.closeSendingDataOverlay = this.closeSendingDataOverlay.bind(this)
    this.closeErrorDataOverlay = this.closeErrorDataOverlay.bind(this)
    this.reCaptchaOnChange = this.reCaptchaOnChange.bind(this)
    this.feedbackReCaptchaOnChange = this.feedbackReCaptchaOnChange.bind(this)
    this.setFormValue = this.setFormValue.bind(this)
    this.setPurchaseFormValue = this.setPurchaseFormValue.bind(this)
    this.setFeedbackFormValue = this.setFeedbackFormValue.bind(this)
    this.toggleMobileView = this.toggleMobileView.bind(this)
    this.mobileBackdrop = document.createElement("div")
    this.mobileBackdrop.classList.add("backdrop")
    this.closeFeedbackModal = this.closeFeedbackModal.bind(this)
    this.sendFeedbackDataToServer = this.sendFeedbackDataToServer.bind(this)
    this.sendFeedbackData = this.sendFeedbackData.bind(this)
    this.formatFeedbackDataForServer = this.formatFeedbackDataForServer.bind(this)

    this.feadbackForm = {email:"", name:"", recaptcha:"", phone:"", questions:[]}
  }

  /**
   * Adds the mobile show more event element
   * @param  {event} e    dom event
   * @param  {node} node html node
   * @return {void}
   */
  toggleMobileView(e, node){
      console.log(node)
      node.classList.toggle("show")
      e.target.innerHTML = node.classList.contains("show") ? "Show Less" : "Estimate Summary - Show More"
      if(node.classList.contains("show"))
        node.parentNode.appendChild(this.mobileBackdrop)
      else
        node.parentNode.removeChild(this.mobileBackdrop)
  }

  /**
   * Converts a string to Title Case
   * @param  {string} str
   * @return {string}
   */
  toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
  }

  /**
   * Calculates the subtotal using all the options, shapes and deminsions.
   * @return {number} subtotal
   */
  calculateSubtotal(){
    var subtotal = 0
    subtotal += this.props.dimension?.total?.sqft ? this.props.stone.unitPrice * parseInt(this.props.dimension.total.sqft) : 0
    subtotal += this.props.dimension?.total?.sqftBacksplash ? this.props.stone.unitPrice * parseInt(this.props.dimension.total.sqftBacksplash) : 0
    subtotal += this.props.dimension?.total?.lftFinishedEdge ? this.props.edge.price * parseInt(this.props.dimension.total.lftFinishedEdge) : 0
    //subtotal += this.props.dimension?.total?.lftApplianceEdge ? this.props.edge.price * parseInt(this.props.dimension.total.lftApplianceEdge) : 0
    subtotal += this.props.edge ? this.props.edge.qty * this.props.edge.price : 0
    if(this.props.corner)
    this.props.corner.map( (item, index) => {
      return subtotal += item.qty * item.price
    })
    subtotal += this.props.sink ? this.props.sink.qty * this.props.sink.price : 0
    if(this.props.cutout)
    this.props.cutout.map( (item, index) => {
      return subtotal += item.qty * item.price
    })
    subtotal += this.props.plumbing?.disconnect ? this.props.plumbing.disconnect.qty * this.props.plumbing.disconnect.price : 0
    subtotal += this.props.plumbing?.connect ? this.props.plumbing.connect.qty * this.props.plumbing.connect.price : 0
    subtotal += this.props.removal ? this.props.removal.qty * this.props.removal.price : 0
    if(this.props.product)
    this.props.product.map( (item, index) => {
      return subtotal += item.qty * item.price
    })
    subtotal += this.props.removal?.countertop?.price ? this.props.removal?.countertop.price * ( this.props.removal?.countertop.qty > 0 ? this.props.removal?.countertop.qty : this.props.dimension.total.sqft) : 0

    // SINKS
    if(this.props?.sink?.id === 2 && this.props.sinks){
      this.props.sinks.forEach((item, index) => {
        if(item.selected.length > 0){
          item.selected.forEach((item, index) => {
            subtotal += item.qty * item.price
          })
        }
      })
    }

    return subtotal
  }

  /**
   * Updates the subtotal evertime the component updates
   * @param  {object} prevProps
   * @param  {object} prevState
   * @return {void}
   */
  componentDidUpdate(prevProps, prevState) {
    const subtotal = this.calculateSubtotal()
    if(prevState.subtotal !== subtotal ){
      this.setState({
        subtotal: subtotal
      })
    }
    this.buildSummary();
  }

  /**
   * Shows the contact modal form
   * @return {void}
   */
  showContactModal(){
    this.setState({
      form: {email:"", name:"", recaptcha:"", phone:"", additionalInfo:""},
      isContactModalOpen: true
    })
  }

  showPurchaseModal(){ 
    this.setState({
      //form: {email:"mike.m@rccwebmedia.com", name:"test", recaptcha:"1", phone:"test", additionalInfo:"test"},
      form: {email:"", name:"", recaptcha:"1234567890", phone:"", additionalInfo:""},
      isPurchaseModalOpen: true
    })
  }


  /**
   * Shows the feedback modal form.
   * @param (string) Optional message. Currently it's the result of the send estimate email process.
   * @return {void}
   */
  showFeedbackModal(msg){
    this.feedback = {}
    this.setState({
      feedbackForm: {msg:msg},
      isFeedbackModalOpen: true
    })
  }

  /**
   * Closes the contact modal form
   * @return {void}
   */
  hideContactModal(){
    this.setState({
      isContactModalOpen: false
    })
  }

  hidePurchaseModal(){
    this.setState({
      isPurchaseModalOpen: false
    })
  }

  /**
   * Close the feedback modal form
   * @return {void}
   */
  closeFeedbackModal(){
    this.setState({
      isFeedbackModalOpen: false
    })
  }

  /**
   * Closes the alert modal
   * @return {void} ]
   */
  closeAlertModal(){
    this.setState({
      estimateDataSent: false
    })
  }

  /**
   * Shows alert modal with the passed values
   * @param  {string} title modal title
   * @param  {string} msg   modal message
   * @return {void}
   */
  showAlertModal(title, msg){
    const alert = this.state.alert
    alert.title = title
    alert.msg = msg
    this.setState({
      estimateDataSent: true,
      alert: alert
    })
  }

  /**
   * Hides the sending email overlay
   * @return {void}
   */
  closeSendingDataOverlay(){
    this.setState({
      isSendingData: false
    })
  }

  /**
   * Shows the sending email overlay
   * @return {void}
   */
  showSendingDataOverlay(){
    this.setState({
      isSendingData: true
    })
  }

  /**
   * Closes the error overlay and resets the sending overlay.
   * @return {void}
   */
  closeErrorDataOverlay(){
    this.setState({
      isSendingData:false,
      error: null
    })
  }

  /**
   * Sets the form value in the object
   * @param {string} key
   * @param {any} value
   */
  setFormValue(key, value){
    const form = this.state.form
    form[key] = value
    this.setState({
      form: form
    })
  }

  setPurchaseFormValue(key, value){
    const form = this.state.purchaseForm
    form[key] = value
    this.setState({
      purchaseForm: form
    })
  }
  /**
   * Sets the form value in the object
   * @param {string} key
   * @param {any} value
   */
  setFeedbackFormValue(key, value, originalQuestion){
    // Create the object to send to the server.
    this.feadbackForm.name = this.state.form.name
    this.feadbackForm.email = this.state.form.email
    this.feadbackForm.phone = this.state.form.phone

    if(key === 'recaptcha')
      this.feadbackForm.recaptcha = value.length > 0

    if( key !== "recaptcha"){
      const question = {key: key, question: originalQuestion, answer: value }
      const oldQuestion = this.feadbackForm.questions.find(i => i.key === key);
      if( oldQuestion ){
        const index = this.feadbackForm.questions.indexOf(oldQuestion);
        this.feadbackForm.questions.splice(index, 1);
      }
      this.feadbackForm.questions.push(question)
    }

    //console.log("setFeedbackFormValue", this.feadbackForm)

    const form = this.state.feedbackForm
    form[key] = value
    this.setState({
      feedbackForm: form
    })
  }

  formatFeedbackDataForServer(){
    const feedbackData = cloneDeep(this.feadbackForm)
    feedbackData.recaptcha = true
    const questions = []
    feedbackData.questions.forEach(item => {
      questions.push(item.question + "|" + item.answer)
    })
    feedbackData.questions = questions
    console.log("formatFeedbackDataForServer", feedbackData)
    return feedbackData
  }

  /**
   * Stores the result of the recaptcha. To be used for validation here nad the server.
   * @param  {string} r response from recaptcha process
   * @return {void}
   */
  reCaptchaOnChange(r){
    //console.log("reCaptchaOnChange", r)
    const recaptcha = r !== null ? r : ""
    this.setFormValue("recaptcha", recaptcha)
  }

  /**
   * Stores the result of the recaptcha. To be used for validation here nad the server.
   * @param  {string} r response from recaptcha process
   * @return {void}
   */
  feedbackReCaptchaOnChange(r){
    //console.log("reCaptchaOnChange", r)
    const recaptcha = r !== null ? r : ""
    this.setFeedbackFormValue("recaptcha", recaptcha)
  }

  /**
   * Checks to makes all required contact form fields are populated and valid. Name, email, ReCAPTCHA
   * @return {bool}
   */
  formIsValid(){
    return this.state.form.name?.length > 0 && this.validEmail(this.state.form.email) && this.state.form.recaptcha?.length > 0
  }

  purchaseFormIsValid(){
    return this.validEmail(this.state.form.email) && this.state.form.recaptcha?.length > 0
  }

  feedbackFormIsValid(){
    return this.state.feedbackForm.recaptcha?.length > 0
  }

  /*
  purchaseFormIsValid(){
    return this.state.purchaseForm.name?.length > 0 && this.validEmail(this.state.purchaseForm.email) && this.state.purchaseForm.recaptcha?.length > 0
  }
  */
  /**
   * Validates the email address
   * @param  {string} email
   * @return {bool}
   */
  validEmail(email){
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
  }

  /**
   * Checks if the contact form is valid. then calls the functions
   * in the send process.
   * @return {void}
   */
  sendEstimateData(){
    if( this.formIsValid() ){
      this.sendEstimateDataToServer()
      this.hideContactModal()
      this.hidePurchaseModal()
      this.showSendingDataOverlay()
    }
    else {
      this.showAlertModal("Missing Information", "You are missing some information. Please take a look and try again.")
    }
  }

  sendPurchaseData(){
    if(this.purchaseFormIsValid()){
      this.sendPurchaseDataToServer()
      this.hidePurchaseModal()
    }
    else {
      this.showAlertModal("Missing Information", "You are missing some information. Please take a look and try again.")
    }
  }

  sendPurchaseDataToServer() {
    //console.log(JSON.stringify(this.summary))
    //https://www.hardrockstoneworks.com/estimator/api/send/estimate
    //fetch("https://www.hardrockstoneworks.com/estimator/api/send/estimate", {
      var estimateId = `${this.props.localization.modal.send.estimatorIdPrefix}${Math.floor(Math.random() * Date.now())}`
      this.summary.estimateId = estimateId
      //fetch("http://hardrock.rccwebmedia.com/estimator/api/send/estimate", {
      fetch("https://www.hardrockstoneworks.com/estimator/api/send/estimate", {
        method: 'post',
        //body: JSON.stringify({"props":this.props, "form": this.state, "shapeImageBase64": this.getShapesImageBase64()})
        body: JSON.stringify(this.summary)
      })
      .then( repsonse =>{
        //console.log("repsonse", repsonse)
        //console.log("repsonse", repsonse.text().then((r)=>console.log(r)))
        return repsonse.json()
      })
      .then(
        (result) => {
          console.log("result", result.isError)
          const alert = {title: "", msg: "", errNumber: "", errText:""}
          alert.title = "Esitmate Sent"
          if(result.isError){
            alert.msg = result.text
            alert.errNumber = result.error.code
            alert.errText = result.error.text
            this.setState({
              estimateDataSent: true,
              alert: alert,
              isSendingData:false
            })
          }
          else{
            alert.msg = result.text
            this.setState({
              isSendingData:false,
              // This would oprn the alert modal with message from server.
              //estimateDataSent: true,
            })
            //Buffer.from(data).toString('base64')
            //window.open(`http://hardrocknop.rccwebmedia.com/estimate?percentCode=${Buffer.from(this.props.localization.modal.send.purchasePercentOfSubtotal.toString()).toString('base64')}&estimateCode=${Buffer.from(this.state.subtotal.toFixed(2)).toString('base64')}&priceCode=${Buffer.from((this.state.subtotal * this.props.localization.modal.send.purchasePercentOfSubtotal).toFixed(2)).toString('base64')}&estimateNumber=${estimateId}`, "_blank")
            window.open(`https://shop.hardrockstoneworks.com/estimate?percentCode=${Buffer.from(this.props.localization.modal.send.purchasePercentOfSubtotal.toString()).toString('base64')}&estimateCode=${Buffer.from(this.state.subtotal.toFixed(2)).toString('base64')}&priceCode=${Buffer.from((this.state.subtotal * this.props.localization.modal.send.purchasePercentOfSubtotal).toFixed(2)).toString('base64')}&estimateNumber=${estimateId}`, "_blank")
            this.showFeedbackModal(result.text)
          }
        },
        (error) => {
          this.setState({
            isSendingData:false,
            error
          })
        }
      )
  }

  /**
   * Sends the summary model to the server along with the contact imformation.
   * Also shows the result modal if an error or questions is success.
   * @return {void}
   */
  sendEstimateDataToServer(){
    //console.log(JSON.stringify(this.summary))
    //https://www.hardrockstoneworks.com/estimator/api/send/estimate
    //fetch("https://www.hardrockstoneworks.com/estimator/api/send/estimate", {
    var estimateId = `${this.props.localization.modal.send.estimatorIdPrefix}${Math.floor(Math.random() * Date.now())}`
    this.summary.estimateId = estimateId
    //fetch("http://hardrock.rccwebmedia.com/estimator/api/send/estimate", {
    fetch("https://www.hardrockstoneworks.com/estimator/api/send/estimate", {
      method: 'post',
      //body: JSON.stringify({"props":this.props, "form": this.state, "shapeImageBase64": this.getShapesImageBase64()})
      body: JSON.stringify(this.summary)
    })
    .then( repsonse =>{
      console.log("repsonse", repsonse)
      //console.log("repsonse", repsonse.text().then((r)=>console.log(r)))
      return repsonse.json()
    })
    .then(
      (result) => {
        console.log("result", result.isError)
        const alert = {title: "", msg: "", errNumber: "", errText:""}
        alert.title = "Esitmate Sent"
        if(result.isError){
          alert.msg = result.text
          alert.errNumber = result.error.code
          alert.errText = result.error.text
          this.setState({
            estimateDataSent: true,
            alert: alert,
            isSendingData:false
          })
        }
        else{
          alert.msg = result.text
          this.setState({
            isSendingData:false,
            // This would oprn the alert modal with message from server.
            //estimateDataSent: true,
          })
          this.showFeedbackModal(result.text)
        }
      },
      (error) => {
        this.setState({
          isSendingData:false,
          error
        })
      }
    )
  }

  /**
   * Checks if the feedback form is valid. then calls the functions
   * in the send process.
   * @return {void}
   */
  sendFeedbackData(){
    this.sendFeedbackDataToServer()
    this.closeFeedbackModal()
    this.showSendingDataOverlay()
  }

  /**
   * Sends the feedback model to the server along with the contact imformation.
   * Also shows the result modal if an error or questions is success.
   * @return {void}
   */
  sendFeedbackDataToServer(){
    //console.log(JSON.stringify(this.summary))
    //https://www.hardrockstoneworks.com/estimator/api/send/feedback
    //fetch("http://hardrock.rccwebmedia.com/estimator/api/send/feedback", {
    fetch("https://www.hardrockstoneworks.com/estimator/api/send/feedback", {
      method: 'post',
      //body: JSON.stringify({"props":this.props, "form": this.state, "shapeImageBase64": this.getShapesImageBase64()})
      body: JSON.stringify(this.formatFeedbackDataForServer())
    })
    .then( repsonse =>{
      console.log("repsonse", repsonse)
      //console.log("repsonse", repsonse.text().then((r)=>console.log(r)))
      return repsonse.json()
    })
    .then(
      (result) => {
        console.log("result", result.isError)
        const alert = {title: "", msg: "", errNumber: "", errText:""}
        alert.title = "Feedback Sent"
        if(result.isError){
          alert.msg = result.text
          alert.errNumber = result.error.code
          alert.errText = result.error.text
          this.setState({
            estimateDataSent: true,
            alert: alert,
            isSendingData:false
          })
        }
        else{
          alert.msg = result.text
          this.setState({
            estimateDataSent: true,
            alert: alert,
            isSendingData:false
          })
        }
        /*
        this.setState({
          estimateDataSent: true,
          alert: alert,
          isSendingData:false
        })
        */
        /*
        this.setState({
          isSendingData:false,
          estimateDataSent: true
        })
        */
      },
      (error) => {
        this.setState({
          isSendingData:false,
          error
        })
      }
    )
  }

  /**
   * Gets the shape image
   * @return {object} img element I think.
   */
  getShapesImage(){
    return sessionStorage.getItem("shapesImg")
  }

  /**
   * Gets the shapes base64 image from the session storage. It's used in the email
   * @return {string}
   */
  getShapesImageBase64(){
    return sessionStorage.getItem("shapesImgBase64")
  }

  /**
   * Appears to not used. Should remove in the futer.
   * @param  {[type]} uri  [description]
   * @param  {[type]} name [description]
   * @return {[type]}      [description]
   */
  /*
  downloadURI(uri, name) {
    var link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  */

  /**
   * Build the summary model that is send to the server and is used to create an email.
   * @return {void}
   */
  buildSummary(){

    console.log("buildSummary: props", this.props.strings.measurement.shape.side.unit)

    const estimate_total = this.numberFormater.format(this.state.subtotal)

    const color = this.props.stone?.name

    const unit_price = (this.numberFormater.format(this.props.stone?.unitPrice) + " / " + this.props.stone?.unit?.name)

    const total_square_foot = (parseFloat(this.props.dimension?.total?.sqft).toFixed(0) + " " + this.props.stone?.unit?.name)

    const backsplash = (this.props.dimension?.total?.sqftBacksplash + " " + this.props.localization.sqftLbl + " | " + this.numberFormater.format(this.props.dimension?.total?.sqftBacksplash * this.props.stone?.unitPrice))

    const applianceEdge = (this.props.dimension?.total?.lftApplianceEdge + " " + this.props.localization.lftLbl)

    const finishedEdge = (this.props.dimension?.total?.lftFinishedEdge + " " + this.props.localization.lftLbl + " | " + this.numberFormater.format(this.props.dimension?.total?.lftFinishedEdge * this.props.edge?.price))

    const shapes = this.props.dimension?.shapes?.map((shape, index) => {
      return (shape.sqft + ' ' + this.props.stone?.unit?.name)
    })

    const sides = this.props.dimension?.shapes?.map((shape, index) => {
      var sidestring = ""
      Object.keys(shape.sides).map((key, index) => {
        sidestring += key.toUpperCase() + ": " + shape.sides[key] + ", "// + this.props.strings?.measurement?.shape?.side?.unit + ", "
      })
      return sidestring.trim().replace(/,\s*$/, "")
    })

    const edge_style = this.props.edge?.price > 0  ? (this.numberFormater.format(this.props.edge?.price) + " / " + this.props.localization.lftLbl + " - " + this.props.edge?.name) : (this.props.localization.noChargeLbl + " - " + this.props.edge?.name)

    const sink = this.props.sink?.name ? this.props.sink?.name : this.props.localization.noLbl

    var cutouts = this.props.cutout?.map((item, index) => {
      return item.name + "," + this.props.localization.qtyLbl + " " + item.qty + " | " + this.numberFormater.format((item.qty * item.price))
    })
    cutouts = cutouts ? cutouts : []

    var products = this.props.product?.map((item, index) => {
      return item.imageSrc + "," + item.name + "," + this.props.localization.qtyLbl + " " + item.qty + " | " + this.numberFormater.format((item.qty * item.price))
    })
    products = products ? products : []

    const plumbing = {
      "disconnect": (this.props.plumbing?.disconnect?.shortLabel ? this.props.plumbing?.disconnect?.shortLabel : this.props.localization.diyLbl) +  " " + (this.props.plumbing?.disconnect?.price > 0 ? this.numberFormater.format((this.props.plumbing?.disconnect?.qty * this.props.plumbing?.disconnect?.price)) : ""),
      "connect": (this.props.plumbing?.connect?.shortLabel ? this.props.plumbing?.connect?.shortLabel : this.props.localization.diyLbl) +  " " + (this.props.plumbing?.connect?.price > 0 ? this.numberFormater.format((this.props.plumbing?.connect?.qty * this.props.plumbing?.connect?.price)) : "")
    }

    var removalCost = (this.props.removal?.countertop?.price * ( this.props.removal?.countertop?.qty > 0 ? this.props.removal?.countertop?.qty : this.props.dimension?.total?.sqft ))
    removalCost = isNaN(removalCost) ? "0" : removalCost
    const removal = {
      "source": this.props.removal?.shortLabel ? this.props.removal?.shortLabel : this.props.localization.diyLbl,
      "material": this.props.removal?.countertop?.name ? this.props.removal?.countertop?.name : "--",
      "sqft": this.toTitleCase(this.props.localization.sqftLbl) + ": " + ( this.props.removal?.countertop?.qty > 0 ? this.props.removal?.countertop?.qty : this.props.dimension?.total?.sqft ) + " | " + this.numberFormater.format(removalCost)
    }

    const profile = {
      "name": this.state.form?.name,
      "email": this.state.form?.email,
      "phone": this.state.form?.phone ? this.state.form?.phone : "--",
      "additionalInfo": this.state.form?.additionalInfo ? this.state.form?.additionalInfo : "--",
    }

    const category = this.props.stone?.category?.name

    const recaptcha = this.state.form.recaptcha?.length > 0 ? true : false
    const recaptchaValue = this.state.form.recaptcha ? this.state.form.recaptcha : ""

    const sku = this.props.stone?.sku.length > 0 ? this.props.stone?.sku : ""

    const sinks = []
    if(this.props?.sink?.id === 2 && this.props.sinks){
      this.props.sinks.forEach((item, index) => {
        //const type = {type:"",selected:[]}
        //type.type = item.name
        if(item.selected.length > 0){
          //const items = []
          item.selected.forEach((item, index) => {
            sinks.push(item.imageSrc + "," + item.name + "," + item.type + "," + this.props.localization.qtyLbl + " " + item.qty + " | " + this.numberFormater.format((item.qty * item.price)))
          })
          //type.selected = items
        }
        //sinks.push(type)
      })
    }


    this.summary.estimateTotal = estimate_total
    this.summary.color = color
    this.summary.unitPrice = unit_price
    this.summary.totalSqFt = total_square_foot
    this.summary.backplash = backsplash
    this.summary.finishedEdge = finishedEdge
    this.summary.applianceEdge = applianceEdge
    this.summary.shapes = shapes
    this.summary.sides = sides
    this.summary.edgeStyle = edge_style
    this.summary.sink = sink
    this.summary.cutouts = cutouts
    this.summary.products = products
    this.summary.plumbing = plumbing
    this.summary.removal = removal
    this.summary.category = category
    this.summary.profile = profile
    this.summary.recaptcha = recaptcha
    this.summary.recaptchaValue = recaptchaValue
    this.summary.shapeImgBase64 = this.getShapesImageBase64()
    this.summary.stoneColorImg = this.props.stone.imageSrc ? this.props.stone.imageSrc : "/images/no-stone-color-img.jpg"
    this.summary.sku = sku
    this.summary.sinks = sinks

    console.log("buildSummary", this.summary)

  }

  /**
   * Checks if the minimum sqft has been meet.
   * @return {Boolean} [description]
   */
  hasMinimumSqFt(){
    return this.props.dimension.hasMinimumSqFt
  }

  /**
   * Checks if a shape has an error
   * @return {Boolean}
   */
  hasShapeError(){
    return this.props.dimension?.shapes?.find(s => s.error === true) !== undefined
  }

  /**
   * Checks to makes conditions are meet to send the estimate
   * @return {void}
   */
  canSendEstimate(){
    return this.hasMinimumSqFt() && !this.hasShapeError()
  }

  /**
   * Creates an array of sink line items.
   * @param  {Object} type Array of sinks for type. i.e. Kitchen, Bar, ...
   * @return {array}   UI Compnents
   */
  formatSinkForType(type){
    const items = []
    type.selected.forEach((item, index) => {
      items.push(<SummaryDetail key={index} labelClassName="label" valueClassName="value" label={item.name} value={this.props.localization.qtyLbl + " " + item.qty + " | " + this.numberFormater.format((item.qty * item.price))} />)
    })
    return (items)
  }

  buildFeedbackForm(){
    return (<div></div>)
  }

  /**
   * [render description]
   * @return {[type]} [description]
   */
  render() {
    //console.log("Summary", this.props.dimension?.shapes?.find(s => s.error === true))
    const { error, isSendingData } = this.state;
    if (error) {
      return <div className="loading sending-email d-flex align-items-center justify-content-center flex-column"><div className="text-white">Error: {error.message}</div><div className="overlay-close-btn" onClick={this.closeErrorDataOverlay}><FontAwesomeIcon icon="times-circle" className="text-white"/> CLOSE</div></div>;
    }
    else {
      return (
        <div className={!this.hasMinimumSqFt() ? "estimator-summary mt-5" : "estimator-summary"}>

          {/* MINIMUN SQ.FT. ERROR - NOT USED */}
          {
            !this.hasMinimumSqFt() ? (<div className="text-white bg-danger mb-3 p-3 d-none"><FontAwesomeIcon icon="exclamation-triangle" size="1x" className="text-white mr-1"/> A minimum area of 25 sq. ft. is required. Please choose larger dimensions.</div>) : ("")
          }

          {/* MOBILE VIEW TOGGLE BUTTON */}
          <div className="d-xl-none text-center bg-white text-primary p-2 h2 sticky-top" onClick={(e)=>this.toggleMobileView(e, e.target.parentNode.parentNode)}>Estimate Summary - Show More</div>

          {/* COLOR IMAGE */}
          {
            this.props.stone !== undefined ? (<div className="summary-stone-image border" style={{background:'url("' + this.props.stone.imageSrc + '")'}}></div>) : ("")
          }

          {/* SUBTOTAL */}
          <h5 className="mt-3">{this.props.localization.sections.total}</h5>
          {
            this.canSendEstimate() ? (<div className="estimator-summary-total">{ this.numberFormater.format(this.state.subtotal) }</div>) : ("---")
          }

          {/* SEND ESTIMATE BUTTON */}
          <div className="my-3"><button className="btn btn-send-estimate btn-block rounded-0 font-weight-bold" onClick={this.showContactModal} disabled={!this.canSendEstimate()}>{this.props.localization.sendEstimateBtnLbl}</button></div>
          <div className="my-3"><button className="btn btn-send-purchase btn-block rounded-0 font-weight-bold" onClick={this.showPurchaseModal} disabled={!this.canSendEstimate()}>{this.props.localization.purchaseEstimateBtnLbl}</button></div>
          
          <h5 className="mt-3">{this.props.localization.sections.details}</h5>

          {/* STONE COLOR */}
          {
            this.props.stone !== undefined ? (<SummaryDetail labelClassName="label" valueClassName="value" label={this.props.localization.colorLbl} value={this.toTitleCase(this.props.stone.name)}/>) : ("")
          }

          {/* UNIT PRICE */}
          {
            this.props.stone !== undefined ? (<SummaryDetail labelClassName="label" valueClassName="value" label={this.props.localization.unitPriceLbl} value={this.numberFormater.format(this.props.stone.unitPrice) + " / " + this.props.stone?.unit?.name}/>) : ("")
          }

          {/* TOTAL SQ.FT. */}
          {
            this.props.dimension?.total?.sqft !== undefined ? (<SummaryDetail labelClassName="label" className={ this.hasShapeError() ? "text-danger shape-error" : ""} valueClassName="value" label={this.props.localization.ttsfLbl} value={ (this.hasShapeError() ? "--" : parseFloat(this.props.dimension?.total?.sqft).toFixed(0)) + " " + this.props.stone?.unit?.name}/>) : ("")
          }

          {/* SHAPES */}
          {
            this.props.dimension?.shapes.length > 0 ? (<div className="h6 mt-3">{this.props.localization.sections.shapes}</div>) : ("")
          }
          {
            this.props.dimension?.shapes?.map((shape, index) => {
              if(!shape.error)
                return (<SummaryDetail key={index} labelClassName="label" valueClassName="value" label={this.props.localization.shapeLbl + " " + (index+1)} value={shape.sqft + ' ' + this.props.stone?.unit?.name}/>)
              else
                return (<SummaryDetail key={index} className="text-danger shape-error" labelClassName="label" valueClassName="value" label={this.props.localization.shapeLbl + " " + (index+1)} value={'-- ' + this.props.stone?.unit?.name}/>)
            })
          }

          {/* BLACKSPLASHES AND EDGES */}
          {
            this.props.dimension?.shapes.length > 0 ? (<div className="h6 mt-3">Edges & Backsplash</div>) : ("")
          }

          {/* BACKSPLASH */}
          {
            (<SummaryDetail labelClassName="label" className={ this.hasShapeError() ? "text-danger shape-error" : ""} valueClassName="value" label={this.props.localization.backsplashLbl} value={((this.hasShapeError() ? "--" : this.props.dimension.total.sqftBacksplash) + " " + this.props.localization.sqftLbl + (this.hasShapeError() || this.props.dimension.total.sqftBacksplash === 0 ? "" : " | " + this.numberFormater.format(this.props.dimension.total.sqftBacksplash * this.props.stone?.unitPrice)))} />)
          }

          {/* EDGE STYLE */}
          {
            this.props.edge !== undefined ? (<SummaryDetail labelClassName="label" valueClassName="value" label={this.props.localization.edgeStyleLbl} value={this.props.edge.price > 0  ? (this.numberFormater.format(this.props.edge.price) + " / " + this.props.localization.lftLbl + " - " + this.props.edge.name) : (this.props.localization.noChargeLbl + " - " + this.props.edge.name)} />) : ("")
          }

          {/* FINISHED EDGE */}
          {
            (<SummaryDetail labelClassName="label" className={ this.hasShapeError() ? "text-danger shape-error" : ""} valueClassName="value" label={this.props.localization.finishedEdgeLbl} value={((this.hasShapeError() ? "--" : this.props.dimension?.total?.lftFinishedEdge) + " " + this.props.localization.lftLbl + (this.hasShapeError() || this.props.dimension?.total?.lftFinishedEdge === 0 ? "" : " | " + this.numberFormater.format(this.props.dimension?.total?.lftFinishedEdge * this.props.edge?.price)))} />)
          }

          {/* APPLIANCE EDGE */}
          {
             (<SummaryDetail labelClassName="label" className={ this.hasShapeError() ? "text-danger shape-error" : ""} valueClassName="value" label={this.props.localization.applianceEdgeLbl} value={((this.hasShapeError() ? "--" : this.props.dimension?.total?.lftApplianceEdge) + " " + this.props.localization.lftLbl + (this.hasShapeError() || this.props.dimension?.total?.lftApplianceEdge === 0 ? "" : ""))} />)
          }

          {/* TOTAL LINEAR FEET - NOT USED */}
          {
            this.props.dimension?.total?.linear !== undefined ? (<SummaryDetail labelClassName="label" valueClassName="value" label="Total Linear Finish" value={this.props.dimension.total.linear ? ( this.props.dimension.total.linear + " " + this.props.localization.ftLbl ) : ("0 " + this.props.localization.ftLbl)}/>) : ("")
          }

          {/* CORNER RADIUS - NOT USED */}
          {
            this.props.corner?.map((item, index) => {
              return (<SummaryDetail key={index} labelClassName="label" valueClassName="value" label={"Corner Radius: " + item.shortLabel} value={this.numberFormater.format((item.qty * item.price))}/>)
            })
          }

          {/* SINK */}
          {
            this.props.dimension?.shapes.length > 0 ? (<div className="h6 mt-3">Sink</div>) : ("")
          }
          {
            this.props.sink !== undefined ? (<SummaryDetail labelClassName="label" valueClassName="value" label={this.props.localization.sinkLbl} value={this.props.sink.name} />) : ("")
          }

          {/* SINKS */}
          {
            this.props.sinks?.map((item, index) => {
              if(this.props.sink !== undefined && this.props.sink.id === 2){
                if(item.selected.length > 0){
                  const items = []
                  items.push(<div key={item.name} className="font-weight-bold small pl-2">{item.name}</div>)
                  item.selected.forEach((item, index) => {
                    items.push(<SummaryDetail key={index} labelClassName="label" valueClassName="value" label={item.name} value={this.props.localization.qtyLbl + " " + item.qty + " | " + this.numberFormater.format((item.qty * item.price))} />)
                  })
                  return(items)
                }
                else {
                  return ("")
                }
              }
            })
          }

          {/* CUTOUTS */}
          {
            this.props.cutout?.length > 0 ? (<div className="h6 mt-3">{this.props.localization.sections.cutouts}</div>) : ("")
          }
          {
            this.props.cutout?.map((item, index) => {
              return (<SummaryDetail key={index} labelClassName="label" valueClassName="value" label={item.name} value={this.props.localization.qtyLbl + " " + item.qty + " | " + this.numberFormater.format((item.qty * item.price))}/>)
            })
          }

          {/* PLUMBING */}
          {
            (this.props.plumbing?.disconnect || this.props.plumbing?.connect)  !== undefined ? (<div className="h6 mt-3">{this.props.localization.sections.plumbing}</div>) : ("")
          }
          {
            this.props.plumbing?.disconnect !== undefined ? (<SummaryDetail labelClassName="label" valueClassName="value" label={this.props.localization.disconnectLbl} value={this.props.plumbing?.disconnect.shortLabel +  " " + (this.props.plumbing?.disconnect.price > 0 ? this.numberFormater.format((this.props.plumbing?.disconnect.qty * this.props.plumbing?.disconnect.price)) : "")} />) : ("")
          }
          {
            this.props.plumbing?.connect !== undefined ? (<SummaryDetail labelClassName="label" valueClassName="value" label={this.props.localization.connectLbl}  value={this.props.plumbing?.connect.shortLabel +  " " + (this.props.plumbing?.connect.price > 0 ? this.numberFormater.format((this.props.plumbing?.connect.qty * this.props.plumbing?.connect.price)) : "")} />) : ("")
          }

          {/* REMOVAL */}
          {
            this.props.removal !== undefined ? (<div className="h6 mt-3">{this.props.localization.sections.removal}</div>) : ("")
          }
          {
            this.props.removal !== undefined ? (<SummaryDetail labelClassName="label" valueClassName="value" label={this.props.localization.removalLbl}value={this.props.removal.shortLabel} />) : ("")
          }
          {
            this.props.removal?.countertop?.name !== undefined ? (<SummaryDetail labelClassName="label" valueClassName="value" label={this.props.localization.materialLbl} value={this.props.removal?.countertop?.name}  />) : ("")
          }
          {
            this.props.removal?.countertop?.price !== undefined ? (<SummaryDetail labelClassName="label" valueClassName="value" label={this.toTitleCase(this.props.localization.sqftLbl)} value={this.props.localization.qtyLbl + " " + ( this.props.removal?.countertop?.qty > 0 ? this.props.removal.countertop.qty : this.props.dimension.total.sqft ) + " | " + this.numberFormater.format((this.props.removal?.countertop?.price * ( this.props.removal?.countertop?.qty > 0 ? this.props.removal.countertop.qty : this.props.dimension.total.sqft ) )) }  />) : ("")
          }

          {/* PRODUCTS */}
          {
            this.props.product?.length > 0 ? (<div className="h6 mt-3">{this.props.localization.sections.products}</div>) : ("")
          }
          {
            this.props.product?.map((item, index) => {
              return (<SummaryDetail key={index} labelClassName="label" valueClassName="value" label={item.name} value={this.props.localization.qtyLbl + " " + item.qty + " | " + this.numberFormater.format((item.qty * item.price))}/>)
            })
          }

          {/* MODALS */}
          <Modal show={this.state.estimateDataSent} onClose={this.closeAlertModal} close={this.props.localization.modal.response.closeBtn} title={this.state.alert.title} className="col-lg-6 col-11 px-0">
            {this.state.alert.errNumber ? (<div className="small font-weight-bold">error: {this.state.alert.errNumber}</div>) : ("")}
            {this.state.alert.errText ? (<div className="">{this.state.alert.errText}</div>) : ("")}
            {this.state.alert.msg}
          </Modal>
          <ConfrimModal show={this.state.isContactModalOpen} onCancel={this.hideContactModal} onConfirm={this.sendEstimateData} confirmEnabled={this.formIsValid()} cancel={this.props.localization.modal.send.closeBtn} confirm={this.props.localization.modal.send.actionBtn} title={"Send Me the Estimate"} className="col-lg-4 col-11 px-0">
            <form>
              <div className="form-group">
                <label>{this.props.localization.modal.send.nameLbl}</label>
                <input type="text" name="name" className="form-control" onChange={(e) => this.setFormValue("name", e.target.value)}/>
              </div>
              <div className="form-group">
                <label>{this.props.localization.modal.send.emailLbl}</label>
                <input type="email" name="email" className="form-control" onChange={(e) => this.setFormValue("email", e.target.value)} />
              </div>
              <div className="form-group">
                <label>{this.props.localization.modal.send.phoneLbl}</label>
                <input type="text" name="phone" className="form-control" onChange={(e) => this.setFormValue("phone", e.target.value)} />
              </div>
              <div className="form-group">
                <label>{this.props.localization.modal.send.infoLbl}</label>
                <textarea type="text" name="additionalInfo" className="form-control" onChange={(e) => this.setFormValue("additionalInfo", e.target.value)}></textarea>
              </div>
              <ReCAPTCHA
                ref={node => {this.recaptchaRef = node;}}
                sitekey="6LeRPmAUAAAAALXSq0A9pdDc6Fc7AXVGAK_XskQX"
                onChange={this.reCaptchaOnChange}
              />
              { this.props.localization.modal.send.additionalInfo ? (<div className="text-muted smaller mt-2">{this.props.localization.modal.send.additionalInfo}</div>) : ("") }
              { this.props.localization.modal.send.disclaimer ? (<div className="text-muted smaller mt-2">{this.props.localization.modal.send.disclaimer}</div>) : ("") }
            </form>
          </ConfrimModal>
          {/* PURCHASE MODAL */}
          <ConfrimModal show={this.state.isPurchaseModalOpen} onCancel={this.hidePurchaseModal} onConfirm={this.sendPurchaseData} confirmEnabled={this.purchaseFormIsValid()} cancel={this.props.localization.modal.send.closeBtn} confirm={this.props.localization.modal.send.purchaseLbl} title={this.props.localization.modal.send.purchaseTitle} className="col-lg-6 col-11 px-0">
            <form>
              <p>{this.props.localization.modal.send.purchaseMessage}</p>
              <div className="form-group d-none">
                <label>{this.props.localization.modal.send.nameLbl}</label>
                <input type="text" name="name" className="form-control" onChange={(e) => this.setFormValue("name", e.target.value)} value={this.state.form.name}/>
              </div>
              <div className="form-group">
                <label>{this.props.localization.modal.send.emailLbl}</label>
                <input type="email" name="email" className="form-control" onChange={(e) => this.setFormValue("email", e.target.value)} value={this.state.form.email}/>
              </div>
              <div className="form-group d-none">
                <label>{this.props.localization.modal.send.phoneLbl}</label>
                <input type="text" name="phone" className="form-control" onChange={(e) => this.setFormValue("phone", e.target.value)} value={this.state.form.phone}/>
              </div>
              <div className="form-group d-none">
                <label>{this.props.localization.modal.send.infoLbl}</label>
                <textarea type="text" name="additionalInfo" className="form-control" onChange={(e) => this.setFormValue("additionalInfo", e.target.value)} value={this.state.form.additionalInfo}></textarea>
              </div>
              <div className="form-group"> 
                <label>{this.props.localization.modal.send.purcahseAmountDue}</label>
                <div>
                  <span className="font-weight-bold" style={{fontSize:"1.5rem"}}>
                  $
                  </span>
                  <span className="font-weight-bold" style={{fontSize:"1.5rem"}}>
                    { (this.state.subtotal * this.props.localization.modal.send.purchasePercentOfSubtotal).toFixed(2) }
                  </span>
                </div>
              </div>
              <div class="d-none">
              <ReCAPTCHA
                ref={node => {this.recaptchaRef = node;}}
                sitekey="6LeRPmAUAAAAALXSq0A9pdDc6Fc7AXVGAK_XskQX"
                onChange={this.reCaptchaOnChange}
              />
              </div>
              { this.props.localization.modal.send.additionalInfo ? (<div className="text-muted smaller mt-2">{this.props.localization.modal.send.additionalInfo}</div>) : ("") }
              { this.props.localization.modal.send.disclaimer ? (<div className="text-muted smaller mt-2">{this.props.localization.modal.send.disclaimer}</div>) : ("") }
            </form>
          </ConfrimModal>
          <ConfrimModal show={this.state.isFeedbackModalOpen} onCancel={this.closeFeedbackModal} onConfirm={this.sendFeedbackData} confirmEnabled={this.feedbackFormIsValid()} cancel={this.props.localization.modal.feedback.closeBtn} confirm={this.props.localization.modal.feedback.actionBtn} title={"Esitmate Sent"} className="col-lg-6 col-11 px-0">
            <form>
              {
                this.state.feedbackForm.msg?.length > 0 ? (<h5 className="font-weight-bold- text-white bg-primary p-3">{this.state.feedbackForm.msg}</h5>) : ("")
              }
              <p>{this.props.localization.modal.feedback.preMsg}</p>
              {
                this.props.localization.modal.feedback?.questions.map((item, i) => {
                  if(item.type === "textarea"){
                    return (<div key={i} className="form-group"><hr/><label className="font-weight-bold">{item.question}</label><textarea defaultValue={this.state.feedbackForm[item.name]} name={item.name} className="form-control" onChange={(e) => this.setFeedbackFormValue(item.name, e.target.value, item.question)} placeholder={item.placeholder}></textarea></div>)
                  }
                  else if(item.type === "text"){
                    return (<div key={i} className="form-group"><hr/><label className="font-weight-bold">{item.question}</label><input defaultValue={this.state.feedbackForm[item.name]} type="text" name={item.name} className="form-control" onBlur={(e) => this.setFeedbackFormValue(item.name, e.target.value, item.question)} placeholder={item.placeholder} /></div>)
                  }
                  else if(item.type === "radio"){
                    var items = []
                    item.options.forEach((option, j) => {
                      items.push(<div className="d-flex align-items-center" key={Math.random()}><input checked={this.state.feedbackForm[item.name] == option.value} type="radio" name={item.name} value={option.value} className="pr-1" onChange={(e) => this.setFeedbackFormValue(item.name, e.target.value, item.question)} id={option.value + ":" + option.lbl} /><label className="pl-1 pr-3 mb-0" htmlFor={option.value + ":" + option.lbl}>{option.lbl}</label></div>)
                    })
                    return (<div key={i} className="form-group"><hr/><label className="font-weight-bold">{item.question}</label><div className={ item.layout === "column" ? "" : "d-flex align-items-center"}>{items}</div></div>)
                  }
                  else if(item.type === "checkbox"){
                    var items = []
                    item.options.forEach((option, j) => {
                      items.push(<div key={Math.random()} className="d-flex align-items-center"><input checked={this.state.feedbackForm[item.name] == option.value} type="checkbox" name={item.name} value={option.value} className="" onChange={(e) => this.setFeedbackFormValue(item.name, e.target.value, item.question)} id={option.value + ":" + option.lbl} /><label className="pl-1 pr-3 mb-0" htmlFor={option.value + ":" + option.lbl}>{option.lbl}</label></div>)
                    })
                    return (<div key={i} className="form-group"><hr/><label className="font-weight-bold">{item.question}</label><div className={ item.layout === "column" ? "" : "d-flex align-items-center"}>{items}</div></div>)
                  }
                  else if(item.type === "select"){
                    var items = []
                    item.options.forEach((option, j) => {
                      items.push(<option selected={this.state.feedbackForm[item.name] == option.value} type="checkbox" value={option.value} onChange={(e) => this.setFeedbackFormValue(item.name, e.target.value)}>{option.lbl}</option>)
                    })
                    return (<div key={Math.random()} className="form-group"><hr/><label className="font-weight-bold">{item.question}</label><select name={item.name} className="form-control">{items}</select></div>)
                  }
                  else {
                    return (<div key={Math.random()}>Missing Element Handler</div>)
                  }
                })

              }
              <hr/>
              <ReCAPTCHA
                ref={node => {this.recaptchaRef = node;}}
                sitekey="6LeRPmAUAAAAALXSq0A9pdDc6Fc7AXVGAK_XskQX"
                onChange={this.feedbackReCaptchaOnChange}
              />
              <p className="mt-3">{this.props.localization.modal.feedback.postMsg}</p>
            </form>
          </ConfrimModal>
          {
            isSendingData ? (<div className="loading sending-email d-flex align-items-center justify-content-center flex-column">
              <div><FontAwesomeIcon icon="envelope" spin size="8x" className="text-white"/></div>
              <div className="small mt-4 text-white">{this.props.localization.sendingLbl}</div>
              <div className="overlay-close-btn" onClick={this.closeSendingDataOverlay}>
                <FontAwesomeIcon icon="slash" size="2x" className="text-white"/>
                <FontAwesomeIcon flip="vertical" transform="left-20" size="2x" icon="slash" className="text-white"/>
              </div>
            </div>) : ("")
          }
        </div>
      )
    }
  }
}

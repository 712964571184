import React from 'react';

/**
 * Confirm Modal UI component.
 * Properties:
 * title,
 * cancel label,
 * confirm label,
 * cancel event handler,
 * confirm event handler,
 * children (an array of components or html),
 * class info
 * @type {Object}
 */
export class ConfrimModal extends React.Component {
  render() {
    // Render nothing if the "show" prop is false
    if(!this.props.show) {
      return null;
    }

    // The gray background
    const backdropStyle = {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(0,0,0,0.3)',
      padding: 50,
      zIndex:9999
    };

    return (
      <div className="d-flex align-items-center justify-content-center 123" style={backdropStyle}>
        <div className={this.props.className + " shadow-sm bg-white"} style={{maxHeight:"100%", overflow:"auto"}}>
          <div className="d-flex align-items-center justify-content-center border-bottom p-2 bg-light w-100"><h6 className="m-0">{this.props.title}</h6></div>
          <div className="px-5 py-3 w-100">
          {this.props.children}
          </div>
          <div className="d-flex w-100 py-3 align-items-center justify-content-end p-3">
            <button className="btn rounded-0 btn-light border px-4" onClick={this.props.onCancel}>
              {this.props.cancel}
            </button>
            <button className="btn rounded-0 btn-primary ml-1 px-4" onClick={this.props.onConfirm} disabled={(this.props.confirmEnabled === undefined ? false : !this.props.confirmEnabled)}>
              {this.props.confirm}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

/**
  * Confirm Modal UI component.
  * Properties:
  * title,
  * children (an array of components or html),
  * close label,
  * close event handler,
  * class info
  * @type {Object}
 */
export class Modal extends React.Component {
  render() {
    // Render nothing if the "show" prop is false
    if(!this.props.show) {
      return null;
    }

    // The gray background
    const backdropStyle = {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(0,0,0,0.3)',
      padding: 50,
      zIndex:99999
    };
    const style = Object.assign(backdropStyle, this.props.style);
    return (
      <div className="d-flex align-items-center justify-content-center" style={style}>
        <div className={this.props.className + " d-flex align-items-center justify-content-center flex-column shadow-sm bg-white fade-in"}>
          <div className="d-flex align-items-center justify-content-center border-bottom p-2 bg-light w-100"><h6 className="m-0">{this.props.title}</h6></div>
          <div className="px-5 py-3">
          {this.props.children}
          </div>
          <div className="d-flex w-100 py-3 align-items-center justify-content-end p-3">
            <button className="btn rounded-0 btn-primary px-4" onClick={this.props.onClose}>
              {this.props.close}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

import React from 'react';

/**
 * Create a select list component.
 * Properties:
 * option model
 * change event sortListOnChangeHandler
 * list classes
 * list type - multiple
 * @param       {object} props
 * @constructor
 */
export function SelectList(props){
    const options = props.options.map((option, index) =>
      <SelectListOption value={option.value} label={option.label} key={index} className={props.listOptionClass} />
    )
    return <select defaultValue={props.defaultValue} className={props.listClass} multiple={props.multiple} size={props.size}  value={props.selected} onChange={(e) => props.onChange(e, props.options[e.target.selectedIndex])}>{options}</select>
}

/**
 * Creates a option element
 * @param       {object} props
 * Properties:
 * option model
 * @constructor
 */
export function SelectListOption(props){
  return <option className={props.class} value={props.value}>{props.label}</option>
}

/**
 * Number Changer UI
 * Properties:
 * value
 * click event handler
 * @type {Object}
 */
export class NumberChanger extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      value: this.props.value
    }
  }

  /**
   * checks to make sure the number is valid then passes to the parent event handler.
   * @param  {number} number
   * @return {void}
   */
  onChangeHandler(number){
    if(isNaN(number)) {
      number = number.replace(/\D/g,'')
      if(number.toString().length === 0)
        number = 0
    }
    else if( !number.toString().length > 0) {
      number = 0
    }
    number = parseInt(number, "10")
    number = number < 0 ? 0 : number
    this.setState({
      value: number
    })
    this.props.onChange(number, this.props.obj)
  }
  increment(e, amount){
    var number = isNaN(parseInt(this.state.value, "10")) ? 0 : parseInt(this.state.value, "10")
    number = (number + amount) < 0 ? 0 : (number + amount)
    this.onChangeHandler(number)
  }

  /**
   * Sets the init value or updates the vlaue
   * @param  {object} prevProps
   * @param  {object} prevState
   * @return {void}
   */
  componentDidUpdate(prevProps, prevState){
    if(this.props.value !== this.state.value)
      this.setState({value: this.props.value})
  }

  render() {
    return (
      <div className="d-flex justify-content-center estimator-detail align-items-center mt-auto">
        <div className="input-group-prepend"><button className="btn btn-light border rounded-0 font-weight-bold" onClick={(e) => this.increment(e, -1)}>-</button></div><input className="border form-control rounded-0 w-25 font-weight-bold text-center" type="text" value={this.state.value} onChange={(e) => this.onChangeHandler(e.target.value)} onBlur={(e) => this.onChangeHandler(e.target.value)} /><div className=" input-group-append"><button className="btn btn-light border rounded-0 font-weight-bold" onClick={(e) => this.increment(e, 1)}>+</button></div>
      </div>
    )
  }
}

/**
 * Checkbox
 * @param {[type]} props 
 */
export const CheckBox = props => {
    return (
      <li>
      <label htmlFor={props.value}>
       <input id={props.value} onClick={props.onClick ? (e) => {props.onClick(e, props)} : (e)=>{console.warn('Undefined onChange event handler')}} onChange={props.onChange ? (e) => {props.onChange(e, props)} : (e)=>{console.warn('Undefined onChange event handler')}} type="checkbox" checked={props.isChecked} value={props.value} /> <span>{props.label}</span>
       </label>
      </li>
    )
}

import React from "react";
import { Group, Line, Label, Text, Tag } from "react-konva";
//import { observer, inject } from "mobx-react";

const METRIC_SIZE = 15;
const PADDING = 5
const FONT_SIZE = 18
const WALL_STROKE_WIDTH = 15
const EDGE_STOKE_WIDTH = 15

/*
  This whole function is calculated in a horible manner. Hopefully I get smarter
  or find someone smarter.
*/
function VerticalMetric({ x, y, width, height, lbl, lblPosition, rotation, againstWall, wallPosition, edge }) {

  const lblX = lblPosition === "left" ? -3 : -3
  const WALL_LINE_X = lblPosition === "left" || wallPosition === "left" ?  METRIC_SIZE + (WALL_STROKE_WIDTH/2) : -METRIC_SIZE-(WALL_STROKE_WIDTH/2)+3

  const degree = Math.abs(360*((rotation/360) % 1))
  var labelX = degree > 90 ? -(lblX-PADDING-2)+3 : (lblX-PADDING-2)-3
  const labelY = degree > 90 ? (height / 2) : (height / 2)

  return (
    <Group x={x} y={y}>
      {
        againstWall?.isAgainst ? (<Line points={[WALL_LINE_X, 0, WALL_LINE_X, height]} stroke={againstWall?.hasBacksplash ? "#224088" : "#fff"} fill="#bbb" strokeWidth={WALL_STROKE_WIDTH} dash={ againstWall?.hasBacksplash ? [] : [20,10]} />) : (<Tag/>)
      }
      {
        edge?.finished ? (<Line points={[WALL_LINE_X, 0, WALL_LINE_X, height]} stroke={"#a300c1"} fill="#bbb" strokeWidth={EDGE_STOKE_WIDTH} />) : (<Tag/>)
      }
      {
        edge?.appliance ? (<Line points={[WALL_LINE_X, 0, WALL_LINE_X, height]} stroke={"#f6ff00"} fill="#bbb" strokeWidth={EDGE_STOKE_WIDTH} />) : (<Tag/>)
      }
      <Line
        points={[0, 0, 0, height]}
        stroke="#bbb"
        fill="#bbb"
        strokeWidth={1}
      />
      <Line points={[-METRIC_SIZE/2, 0, METRIC_SIZE/2, 0]} strokeWidth={1} stroke="#ccc" />
      <Line points={[-METRIC_SIZE/2, height, METRIC_SIZE/2, height]} strokeWidth={1} stroke="#ccc" />
      <Label x={labelX} y={labelY}>
        <Tag fill="white" rotation={rotation} />
        <Text text={lbl} padding={PADDING} fontSize={FONT_SIZE} fontStyle="bold" rotation={rotation}/>
      </Label>
    </Group>
  );
}

/*
  This whole function is calculated in a horible manner. Hopefully I get smarter
  or find someone smarter.
*/
function HorizontalMetric({ x, y, width, height, lbl, lblPosition, rotation, againstWall, wallPosition, edge }) {

  const lblY = lblPosition === "top" ? 5 : 5
  const WALL_LINE_Y = lblPosition === "top" ? (METRIC_SIZE*2)+2 : -WALL_STROKE_WIDTH - 2

  const degree = Math.abs(360*((rotation/360) % 1))
  const labelX = degree > 90 ? ((width / 2)- FONT_SIZE) : ((width / 2)- FONT_SIZE)

  var labelY = degree > 45 ? -(lblY-PADDING-20) : (lblY-PADDING-4)
  labelY = degree > 225 ? -(lblY-PADDING+3) : labelY

  return (
    <Group x={x} y={y}>
    {
      againstWall?.isAgainst ? (<Line points={[0, WALL_LINE_Y, width, WALL_LINE_Y]} stroke={againstWall?.hasBacksplash ? "#224088" : "#fff"} fill="#bbb" strokeWidth={WALL_STROKE_WIDTH} dash={ againstWall?.hasBacksplash ? [] : [20,10]} />) : (<Tag/>)
    }
    {
      edge?.finished ? (<Line points={[0, WALL_LINE_Y, width, WALL_LINE_Y]} stroke={"#a300c1"} fill="#bbb" strokeWidth={EDGE_STOKE_WIDTH} />) : (<Tag/>)
    }
    {
      edge?.appliance ? (<Line points={[0, WALL_LINE_Y, width, WALL_LINE_Y]} stroke={"#f6ff00"} fill="#bbb" strokeWidth={EDGE_STOKE_WIDTH} />) : (<Tag/>)
    }
      <Line
        points={[0, METRIC_SIZE / 2, width, (METRIC_SIZE / 2)]}
        stroke="#ccc"
        fill="#ccc"
        strokeWidth={1}
      />
      <Line points={[0, METRIC_SIZE, 0, 0]} strokeWidth={1} stroke="#ccc" />
      <Line points={[width, METRIC_SIZE, width, 0]} strokeWidth={1} stroke="#ccc" />
      <Label x={labelX} y={labelY} >
        <Tag fill="#fff" rotation={rotation} />
        <Text text={lbl} padding={PADDING} fontSize={FONT_SIZE} fontStyle="bold" rotation={rotation}/>
      </Label>
    </Group>
  );
}

function VerticalMetricOriginal({ x, y, width, height, lbl, lblPosition, rotation, againstWall, wallPosition, edge }) {
  //console.log("VerticalMetric",rotation, Math.abs(rotation))
  const lblX = lblPosition === "left" ? -3 : -3
  const WALL_LINE_X = lblPosition === "left" || wallPosition === "left" ?  METRIC_SIZE + (WALL_STROKE_WIDTH/2) : -METRIC_SIZE-(WALL_STROKE_WIDTH/2)+3
  return (
    <Group x={x} y={y}>
      {
        againstWall?.isAgainst ? (<Line points={[WALL_LINE_X, 0, WALL_LINE_X, height]} stroke={againstWall?.hasBacksplash ? "#224088" : "#fff"} fill="#bbb" strokeWidth={WALL_STROKE_WIDTH} dash={ againstWall?.hasBacksplash ? [] : [20,10]} />) : (<Tag/>)
      }
      {
        edge?.finished ? (<Line points={[WALL_LINE_X, 0, WALL_LINE_X, height]} stroke={"#a300c1"} fill="#bbb" strokeWidth={EDGE_STOKE_WIDTH} />) : (<Tag/>)
      }
      {
        edge?.appliance ? (<Line points={[WALL_LINE_X, 0, WALL_LINE_X, height]} stroke={"#f6ff00"} fill="#bbb" strokeWidth={EDGE_STOKE_WIDTH} />) : (<Tag/>)
      }
      <Line
        points={[0, 0, 0, height]}
        stroke="#bbb"
        fill="#bbb"
        strokeWidth={1}
      />
      <Line points={[-METRIC_SIZE/2, 0, METRIC_SIZE/2, 0]} strokeWidth={1} stroke="#ccc" />
      <Line points={[-METRIC_SIZE/2, height, METRIC_SIZE/2, height]} strokeWidth={1} stroke="#ccc" />
      <Label x={lblX-PADDING-2} y={(height / 2)-FONT_SIZE} rotation={rotation}>
        <Tag fill="white" />
        <Text text={lbl} padding={PADDING} fontSize={FONT_SIZE} fontStyle="bold"/>
      </Label>
    </Group>
  );
}

function HorizontalMetricOriginal({ x, y, width, height, lbl, lblPosition, rotation, againstWall, wallPosition, edge }) {
  //console.log("HorizontalMetric",rotation)
  const lblY = lblPosition === "top" ? 5 : 5
  //const lblY = lblPosition === "top" ? Math.abs(rotation) !== 270 && Math.abs(rotation) !== 0 && Math.abs(rotation) !== 360 ? 28 : 5 : Math.abs(rotation) !== 270 && Math.abs(rotation) !== 0 && Math.abs(rotation) !== 360 ? 28 : 5
  const WALL_LINE_Y = lblPosition === "top" ? (METRIC_SIZE*2)+2 : -WALL_STROKE_WIDTH - 2
  return (
    <Group x={x} y={y}>
    {
      againstWall?.isAgainst ? (<Line points={[0, WALL_LINE_Y, width, WALL_LINE_Y]} stroke={againstWall?.hasBacksplash ? "#224088" : "#fff"} fill="#bbb" strokeWidth={WALL_STROKE_WIDTH} dash={ againstWall?.hasBacksplash ? [] : [20,10]} />) : (<Tag/>)
    }
    {
      edge?.finished ? (<Line points={[0, WALL_LINE_Y, width, WALL_LINE_Y]} stroke={"#a300c1"} fill="#bbb" strokeWidth={EDGE_STOKE_WIDTH} />) : (<Tag/>)
    }
    {
      edge?.appliance ? (<Line points={[0, WALL_LINE_Y, width, WALL_LINE_Y]} stroke={"#f6ff00"} fill="#bbb" strokeWidth={EDGE_STOKE_WIDTH} />) : (<Tag/>)
    }
      <Line
        points={[0, METRIC_SIZE / 2, width, (METRIC_SIZE / 2)]}
        stroke="#ccc"
        fill="#ccc"
        strokeWidth={1}
      />
      <Line points={[0, METRIC_SIZE, 0, 0]} strokeWidth={1} stroke="#ccc" />
      <Line points={[width, METRIC_SIZE, width, 0]} strokeWidth={1} stroke="#ccc" />
      <Label x={(width / 2)- FONT_SIZE} y={lblY-PADDING-4} rotation={rotation}>
        <Tag fill="#fff" />
        <Text text={lbl} padding={PADDING} fontSize={FONT_SIZE} fontStyle="bold"/>
      </Label>
    </Group>
  );
}

/**
 * Layput the deminsional lines for a shape.
 * @extends React
 */
class Metric extends React.Component {
  render(){
    var layout = []
    const _layout = {
      top:{
        x: 0,
        y: -25
      },
      bottom:{
        x: 0,
        y: parseInt(this.props.height) + 10
      },
      left:{
        x: -15,
        y: 0
      },
      right:{
        x: parseInt(this.props.width) + 15,
        y: 0
      }
    }

    layout = _layout[this.props.position]

    if( this.props.position === "custom" )
      layout = this.props.customPositionCoords

    const components = []
    if(this.props.direction === "vertical")
      components.push(
        <VerticalMetric key={1}
          height={this.props.height}
          x={this.props.x}
          y={this.props.y}
          lbl={this.props.label}
          lblPosition={this.props.position}
          rotation = {this.props.rotation}
          againstWall={this.props.wall}
          wallPosition =  {this.props.wallPosition}
          edge = {this.props.edge}
        />
      )
    if(this.props.direction === "horizontal")
      components.push(
        <HorizontalMetric key={1}
          x={this.props.x}
          y={this.props.y}
          width={this.props.width}
          lbl={this.props.label}
          lblPosition={this.props.position}
          rotation = {this.props.rotation}
          againstWall={this.props.wall}
          wallPosition =  {this.props.wallPosition}
          edge = {this.props.edge}
        />
      )
    return(
      <Group {...layout}>
        { components }
      </Group>
    )
  }
}

export  {Metric}

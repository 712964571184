import React from 'react';
import {SelectList, CheckBox} from "./html-elements";
import {Modal} from "./modal"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/************************


 The default selected swatch is determined in the <class>StoneSwatchGrid</class>


 ************************/

 /**
  * The main UI componet used to select, filter, order the stone color options.
  * @type {Object}
  */
export class ColorChooser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        inStockOnly: false,
        colors: [],
        stocked: [],
        sort: 1,
        loaded: false,
        selectedStone: {id: 0, name: ""},
        products : []
    }
    this.sortListOnChangeHandler = this.sortListOnChangeHandler.bind(this);
    this.colorFilterClickHandler = this.colorFilterClickHandler.bind(this)
    this.swatchOnClickHandler = this.swatchOnClickHandler.bind(this)
    this.stockedFilterClickHandler = this.stockedFilterClickHandler.bind(this)
    this.state.selectedStone = this.props.products[1]
    this.props.onSelectedStoneChanged(this.state.selectedStone)
  }

  /**
   * Converts a string to Title Case
   * @param  {string} str
   * @return {string}
   */
  toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
  }

  /**
   * Sets the selected color and sends it up the tree.
   * @param  {event} e    dom event
   * @param  {object} item color model
   * @return {void}
   */
  swatchOnClickHandler(e, item) {
    if(item){
      this.setState({
        selectedStone: item
      })
      this.props.onSelectedStoneChanged(item)
    }
  }

  /**
   * Sets the sorting value
   * @param  {event} e      dom event
   * @param  {object} option filter model
   * @return {void}
   */
  sortListOnChangeHandler(e, item) {
    //console.log("sortListOnChangeHandler")
    this.setState({
      sort: item.value
    })
  }

  /**
   * Sets the instock filter. Not being used because the whole filter system need to be
   * re-worked to handle multiple filters.
   * @param  {event} e      dom event
   * @param  {object} option filter model
   * @return {void}
   */
  stockedFilterClickHandler(e, option){
    var stocked = this.state.stocked
    if(e.target.checked){
      stocked.push(option.value)
    }
    else {
      stocked = stocked.filter( (item, index) => {
           return item !== option.value ? item : null
        }
      )
    }
    this.setState({
      stocked: stocked
    })
  }

  /**
   * Sets the color choice filter
   * @param  {event} e      dom event
   * @param  {object} option filter model
   * @return {void}
   */
  colorFilterClickHandler(e, color){
    var colors = this.state.colors
    if(e.target.checked){
      colors.push(color.name)
    }
    else {
      colors = colors.filter( (item, index) => {
           return item !== color.name ? item : null
        }
      )
    }
    this.setState({
      colors: colors
    })
  }

  /**
   * Resets the color filter options when a new stone category is selected.
   * @param  {object} prevProps
   * @param  {object} prevState
   * @return {void}
   */
  componentDidUpdate(prevProps, prevState) {
    if((prevProps.stoneCategory.value !== this.props.stoneCategory.value) && this.props.products.length > 0){
        this.setState({
          colors: [],
          stocked: []
        })
    }
  }

  /**
   *
   * @return {string}
   */
  render() {
    const products = this.props.products.filter((item) => {
      return item.category.id === this.props.stoneCategory.value
    });
    return (
      <div className="estimator-section">
        <div>
          <h2>{this.props.localization.title}</h2>
          <hr className="section-seperator" />
          { this.props.localization.info ? (<p>{this.props.localization.info}</p>) : ("")}
        </div>
        <div className="d-flex">
          <div className="col-2">
            <StoneColorFilter products={products} onClick={this.colorFilterClickHandler} stoneCategory={this.props.stoneCategory} stocked={this.state.stocked} />
            <StoneStockedFilter products={products} onClick={this.stockedFilterClickHandler} stoneCategory={this.props.stoneCategory} colors={this.state.colors} />
          </div>
          <div className="flex-grow-1 col">
            <div className="d-flex justify-content-between mb-3 mr-3">
              <div>Your Selection: <span className="font-weight-bold">{this.toTitleCase(this.state.selectedStone.name)}</span></div>
              <StonePriceFilter items={sortoptions} onChange={this.sortListOnChangeHandler} products={products} />
            </div>
            <div>
              <StoneSwatchGrid products={products} inStockOnly={this.state.inStockOnly} colors={this.state.colors} stocked={this.state.stocked} sort={this.state.sort} swatchOnClick={this.swatchOnClickHandler} selected={this.state.selectedStone} stoneCategory={this.props.stoneCategory} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

/**
 * Displays all the swatch colors for a stone category
 * @extends React
 */
class StoneSwatchGrid extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      sort: 0,
      products: this.props.products,
      resetSelected: false,
      imageModal:{
        show: false,
        src: "",
        title: "",
        loading: false
      }
    }
    this.selectedStoneOfCategory = {}
    this.onClickHandler = this.onClickHandler.bind(this)
    this.onExpandImageClickHandler = this.onExpandImageClickHandler.bind(this)
    this.closeImageModalHandler = this.closeImageModalHandler.bind(this)
    this.onModalImageLoadHandler = this.onModalImageLoadHandler.bind(this)
  }

 /**
  * [Updates the porduct list order using sorting logic and updates the seleted item when required. The selected item needs to be updated when the product list changes.
  * The product list changes when the stone category value changes.
  * @param  {object} prevProps
  * @param  {object} prevState
  * @return {void}
  */
  componentDidUpdate(prevProps, prevState) {
    const exspectingNewProducts = (this.props.stoneCategory.value !== prevProps.stoneCategory.value || this.state.selected === undefined)
    // If the prev product list != the current product list then it will apply the current sort paramaters to the new product list and force the selected product item to be updated.
    if(exspectingNewProducts || (this.state.sort !== this.props.sort)){
      this.sortProducts(exspectingNewProducts)
    }

    // Second time through reset seleted value, if required.
    if(this.state.resetSelected) {
      // Set the selected value to it's previous state or the default state if it has no previous state.
      const selected = this.selectedStoneOfCategory[this.state.products[0]?.category.id] !== undefined ? this.selectedStoneOfCategory[this.state.products[0]?.category.id] : this.state.products[0]

      this.setState({
        selected: selected,
        resetSelected: false
      })
      this.props.swatchOnClick(null, selected)
    }
  }

  /**
   * Opend the image modal
   * @return {void}
   */
  onModalImageLoadHandler(){
    const imageModal = this.state.imageModal
    imageModal.loading = false
    this.setState({
      imageModal: imageModal
    })
  }

  /**
   * Sets the image model for the modal. Used to display a larger image of the color
   * @param  {event} e     dom event
   * @param  {string} src   image url
   * @param  {string} title modal title
   * @return {void}
   */
  onExpandImageClickHandler(e, src, title){
    this.setState({
      imageModal:{
        show: true,
        loading: true,
        src: src,
        title: title
      }
    })
  }

  /**
   * Closes the image modal
   * @return {void}
   */
  closeImageModalHandler(){
    this.setState({
      imageModal:{
        show:false
      }
    })
  }

  /**
   * Sets the selected swatch and passes it up the tree.
   * @param  {event} e       dom event
   * @param  {object} product color model
   * @return {void}
   */
  onClickHandler(e, product){
    this.selectedStoneOfCategory[product.category.id] = product
    this.props.swatchOnClick(e, product)
  }

  /**
   * Sorts the swatch array using the selected order
   * @param  {Boolean} [resetSelected=false] A true value will trigger update
   * @return {void}
   */
  sortProducts(resetSelected = false){
    const sort = this.props.sort
    var products = this.props.products
    products.sort( (a, b) => {
      switch (sort) {
        // z-a
        case 2:
          return a.name > b.name ? -1 : 1
        // high to low
        case 3:
          return a.unitPrice > b.unitPrice ? -1 : 1
        // low to high
        case 4:
          return a.unitPrice > b.unitPrice ? 1 : -1
        // instock order
        case 5:
          return a.stocked ? -1 : 1
        // a-z
        default:
          return a.name > b.name ? 1 : -1
      }
    })
    if(this.props.products.length > 0){
      this.setState({
        products: products,
        sort: sort,
        resetSelected: resetSelected
      })
    }
    else {
      this.setState({
        products: products,
        sort: sort
      })
    }
  }

  /**
   *
   * @return {object}
   */
  render() {
    const swatches = []
    var products = []

    this.state.products.forEach((product) => {
        if(this.props.colors.length === 0 || this.props.colors.includes(product.color.name))
          products.push(product)
      }
    )

    // Filter stocked items
    products = products.filter((product, index)=>{
        return this.props.stocked.length === 0 || this.props.stocked.includes(product.stocked) ? product : null
    })

    products.forEach((product)=>{
      swatches.push(<StoneSwatch product={product} key={product.id} onClick={this.onClickHandler} selected={this.props.selected} onExpandImageClick={this.onExpandImageClickHandler} />)
    })

    if(this.state.products.length === 0){
      return (
        <div className="font-weight-bold font-italic">No colors available for this stone.</div>
      )
    }
    else {
      return (
        <div>
          <div className="swatch-grid">{swatches}</div>
            <div style={{display: this.state.imageModal.loading ? "none" : "block"}}>
              <Modal show={this.state.imageModal.show} onClose={this.closeImageModalHandler} close={"Close"} title={this.state.imageModal.title} >
                <img src={this.state.imageModal.src} className="img-fluid" alt={this.state.imageModal.title} onLoad={this.onModalImageLoadHandler} />
              </Modal>
            </div>
            <div style={{display: this.state.imageModal.loading ? "block" : "none"}}>
              <div className="loading-expanded-img d-flex align-items-center justify-content-center flex-column">
                <div><FontAwesomeIcon icon="image" spin size="8x" className="text-white"/></div><div className="small mt-3 text-white">LOADING IMAGE</div>
                  <div className="overlay-close-btn" onClick={this.onModalImageLoadHandler}>
                    <FontAwesomeIcon icon="slash" size="2x" className="text-white"/>
                    <FontAwesomeIcon flip="vertical" transform="left-20" size="2x" icon="slash" className="text-white"/>
                  </div>
              </div>
            </div>
        </div>
      )
    }
  }
}

/**
 * [StoneSwatch description]
 * @extends React
 */
class StoneSwatch extends React.Component {
  /**
   * Converts a string to Title Case
   * @param  {string} str
   * @return {string}
   */
  toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
  }
  /**
   * [render description]
   * @return {[type]} [description]
   */
  render() {
    return (
      <div className={this.props.product.id === this.props.selected.id ? "swatch selected position-relative" : "swatch position-relative"}  onClick={(e) => this.props.onClick(e, this.props.product)}>
        <img alt={this.props.product.name} src={this.props.product.imageSrc} className="img-fluid" />
        <div className="position-absolute" style={{top:0,right:0}} title="View larger image"><FontAwesomeIcon icon="expand"  className="text-dark" style={{cursor:"pointer",background:"rgba(255,255,255,.85)",padding:"1px 3px 1px 3px",fontSize:24}} onClick={(e) => this.props.onExpandImageClick(e, this.props.product.imageSrcLg, this.toTitleCase(this.props.product.name))}/></div>
        <div className="product-details d-flex flex-column flex-grow">
          <div className="product-name">{this.toTitleCase(this.props.product.name)}</div>
            <div className="product-price">
              <div>
                <div className="small">Starting at</div>
                <div><sup className="unit-cost-mark">$</sup><span className="unit-cost">{this.props.product.unitPrice}</span> <span className="unit">{this.props.product.unit.name}</span>
              </div>
            </div>
          </div>
          <div className={this.props.product.stocked ? "instock d-none" : "outofstock d-none"}>{this.props.product.stocked ? "In Stock" : "Out of Stock"}</div>
        </div>
      </div>
    )
  }
}

/**
 * [StoneColorFilter description]
 * @extends React
 */
class StoneColorFilter extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      checkboxes: [],
      stocked: []
    }
    this.onClickHandler = this.onClickHandler.bind(this)
  }

  /**
   * Builds the init color filter model using a swatch array
   * @return {void}
   */
  componentDidMount(){
    this.buildColorModel()
  }

  /**
   * Re-Builds the color evertime the stone category changes.
   * @param  {object} prevProps
   * @param  {object} prevState
   * @return {void}
   */
  componentDidUpdate(prevProps, prevState) {
    if(this.props.stoneCategory !== prevProps.stoneCategory){
      this.buildColorModel()
    }
  }

  /**
   * Sets the filters selected and sends it up the tree
   * @param  {event} e    dom event
   * @param  {object} item filter model
   * @return {void}
   */
  onClickHandler = (e, item) => {
    let checkboxes = this.state.checkboxes
    checkboxes.forEach(checkbox => {
       if (checkbox.value === e.target.value)
          checkbox.isChecked =  e.target.checked
    })
    this.setState({checkboxes: checkboxes})
    this.props.onClick(e, item)
  }

  /**
   * Count and filter the unique color name using the swatch array
   * @return {void}
   */
  buildColorModel(){
    const colors = []
    const result  = {}
    // Count and filter the unique color name

    this.props.products.forEach((x) => { result[x.color.name] = (result[x.color.name] || 0)+1; });

    // Create the data for the InputList
    for (let [key, value] of Object.entries(result)) {
      colors.push({value:key, name:key, label:key + " (" + value + ")", isChecked:false})
    }
    colors.sort( (a,b) => {
      return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
    })
    //return colors
    this.setState({
      checkboxes: colors,
      stocked: this.props.stocked
    })
  }

  /**
   *
   * @return {void}
   */
  render() {
    return (
      <div className="px-3">
        <div className="mb-2">Color Family:</div>
        <ul className="list-unstyled">{
          this.state.checkboxes.map((checkbox, index) => {
            return (<CheckBox {...checkbox} key={index} onClick={this.onClickHandler}/>)
          })
        }
        </ul>
      </div>
    )
  }
}

/**
 * UI componet to filter the color swatches based upon stocked. This is not being used because the filter system
 * need to be re-writen to handle multiple filters.
 * @extends React
 */
class StoneStockedFilter extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      checkboxes: this.buildModel()
    }
    this.onClickHandler = this.onClickHandler.bind(this)
  }

  /**
   * Triggers the building the color filter model
   * @param  {object} prevProps
   * @return {void}
   */
  componentDidUpdate(prevProps) {
    if(this.props.stoneCategory !== prevProps.stoneCategory){
      this.setState({
        checkboxes: this.buildModel()
      })
    }
  }

  /**
   * [onClickHandler description]
   * @param  {event} e    dom event
   * @param  {object} item filter model
   * @return {void}
   */
  onClickHandler = (e, item) => {
    let checkboxes = this.state.checkboxes
    checkboxes.forEach(checkbox => {
       if (checkbox.value === e.target.value)
          checkbox.isChecked =  e.target.checked
    })
    this.setState({checkboxes: checkboxes})
    this.props.onClick(e, item)
  }

  /**
   * Builds the model
   * @return {void}
   */
  buildModel(){
    const colors = []
    const result  = {}

    // Count and filter the unique color name
    this.props.products.forEach((x) => { result[x.stocked] = (result[x.stocked] || 0)+1; });

    // Create the data for the InputList
    for (let [key, value] of Object.entries(result)) {
      const bol = key === "true"
      const lbl = bol ? "In Stock" : "Out of Stock"
      colors.push({value:key, name:bol, label:lbl + " (" + value + ")", isChecked:false})
    }
    colors.sort( (a,b) => {
      return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
    })
    return colors
  }

  /**
   * [render description]
   * @return {[type]} [description]
   */
  render() {
    const checkboxes = this.state.checkboxes //this.buildModel()
    return (
      <div className="px-3 d-none">
        <div className="mb-2">Stocked:</div>
        <ul className="list-unstyled">{
          checkboxes.map((checkbox, index) => {
            return (<CheckBox {...checkbox} key={index} onClick={this.onClickHandler}/>)
          })
        }
        </ul>
      </div>
    )
  }
}

/**
 * Sort Model
 * @type {Array}
 */
const sortoptions = [{value: 1, label: "Name A - Z"}, {value: 2, label: "Name Z - A"}, {value: 3, label: "Price High to Low"}, {value: 4, label: "Price Low to High"}]

/**
 * Sort Filter UI
 * @extends React
 */
class StonePriceFilter extends React.Component {
  render() {
    return (
      <div className="d-flex align-items-center"><div className="text-nowrap pr-1">Sort By:</div> <SelectList options={this.props.items} listClass={"form-control d-inline"} onChange={this.props.onChange} /></div>
    )
  }
}

/**
 * The stone category UI. All event handling is in a parent class
 * @type {String}
 */
export class StoneSelector extends React.Component {
  render() {
    return (
      <div className="estimator-section">
        <div>
          <h2>{this.props.localization.title}</h2>
          <hr className="section-seperator" />
          { this.props.localization.info ? (<p>{this.props.localization.info}</p>) : ("")}
        </div>
        <div>
          <SelectList options={this.props.items} onChange={this.props.onChange} listClass="custom-select col-lg-3" />
        </div>
      </div>
    )
  }
}
